<template>
    <div id="account-page pt-5">
        <section id="account" class="pt-5">
            <div class="container-sm pt-5 pt-md-0">
            <h1 class="pt-5">My Account</h1>

            <OrderSummary v-for="order in orders" v-bind:key="order.id" v-bind:order="order"></OrderSummary>

            <button class="btn btn-danger" @click="logout">Log out</button>
        </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import OrderSummary from '@/components/OrderSummary.vue';

export default {
    name: 'MyAccount',
    components: { OrderSummary },
    data() {
        return {
            orders: []
        }
       
    },
    mounted() {
        document.title = 'My account | Rizq'

        this.getMyOrders()
    },
    methods: {
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            
            this.$store.commit('removeToken')

            this.$router.push('/')
        },

        async getMyOrders(){
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/orders/')
                .then(response => {
                    this.orders = response.data
                })
                .catch(error => {
                    console.log(error)
            })
            this.$store.commit('setIsLoading', false)
        }


    }
}
</script>
