<template>
  <section class="pt-5 pt-md-0" id="page-cart">
    <div class="container-sm pt-5 pt-lg-0">
    
      <div class="row " v-if="cartTotalLength">
      <div class="col-12 col-lg-7">
        <div class="table-responsive">
          <table class="table table-striped align-middle h-100">
      <thead>
        <tr>
          <th scope="th"></th>
          <th scope="th-lg">Product</th>
          <th scope="th-lg">Price</th>
          <th scope="th-lg">Quantity</th>
          <th scope="th-lg">Total</th>
          <th scope="th-lg"></th>
        </tr>
      </thead>

      <tbody class="table-group-divider">
        <cartItem v-for="item in cart.items" v-bind:key="item.product.id" v-bind:initialItem="item" v-on:removeFromCart="removeFromCart"/>
      </tbody>
    </table>

  </div>
       
    </div>
    <div class="col-12 col-lg-3 mt-5 mx-auto outerBorder">
      <div class="innerBorder p-5">
        <h3>Payment Details</h3>
        <hr>
        <table class="table ">
          <tbody>
            <tr class="h-100">
              <td class="text-start">Subtotal</td>
              <td class="text-end">€{{ cartSubTotalOfferPrice.toFixed(2) }}</td>
            </tr>
            <tr class="h-100">
              <td class="text-start">Shipping</td>
              <td class="text-end"> <span class="link link success" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-custom-class="custom-tooltip" data-bs-placement="right" data-bs-title="*Shipping charges apply for orders below 39€">
                  <i class="fa-solid fa-circle-info ms-1 mt-2 text-success text-muted" style="font-size: larger;"></i>
                </span>
               {{ shippingFee }} 
               
              </td>
            </tr>

            <tr class="h-100 pt-3">
              <td class="text-start fs-4 pt-5 fw-bolder">Total</td>
              <td class="text-end fs-4 text-success pt-5 fw-bolder">€{{ cartTotalOfferPrice.toFixed(2) }} </td>
            </tr>
          </tbody>
          
        </table>
       
      
        <router-link :to="{ name: 'CheckOut' }" class="btn btn-outline-success m-5 w-100 mx-auto fw-bolder fs-5">Proceed to Checkout</router-link>
        <router-link :to="{ name:'Products'}" class="link link-success link-underline link-underline-opacity-0 m-2 w-100 text-start fs-6 "><i class="fa-solid fa-arrow-left me-2 "></i>Return to shopping</router-link>
      </div>
    </div>

  </div>
  <div v-else>
    <p class="pt-4 pt-md-0 ">You do not have any products in your cart...</p>
    <router-link :to="{ name:'Products'}" class="btn btn-outline-success m-5 w-100 pt-5 mx-auto">Back to Shopping</router-link>
  </div>
    
    </div>
    
   
   
  </section>

</template>

<script>
import axios from "axios";
import CartItem from "../components/CartItem.vue";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import { Tooltip } from "bootstrap/dist/js/bootstrap.min"; 
export default {
  name: "CartView",
  components: { CartItem },
  data() {
    return {
      cart: {
        items: [],
      },
      shippingFee: 0,
      shippingFree: false
    };
  },
  mounted() {
    this.cart = this.$store.state.cart;
    
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
    
   
  },
  methods: {
    removeFromCart(item) {
        this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)

    }
  },
  computed: {
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
    cartSubTotalOfferPrice(){
      var total = 0
      this.cart.items.forEach((item) => {
        if(item.product.offer){
          const total_price = item.quantity * item.product.offerPrice 
          total += total_price 
        }
        else {
          const total_price = item.quantity * item.product.price
          total += total_price 
        }
      })  
      return parseFloat(total.toFixed(2))
    },
    cartTotalOfferPrice(){
      var total = 0
      this.cart.items.forEach((item) => {
        if(item.product.offer){
          const total_price = item.quantity * item.product.offerPrice 
          total += total_price 
        }
        else {
          const total_price = item.quantity * item.product.price
          total += total_price 
        }
      })  
     if(parseFloat(total.toFixed(2)) < 39) {
        total += 3.99
        this.shippingFee = '€3.99'
      
     }
     else {
      this.shippingFee ='Free'
     }
     return parseFloat(total.toFixed(2))
    },
   
  },
};
</script>

<style scoped>

tr {
      height: 100px;
    }

.innerBorder {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-shadow: 0 20px 40px #aef5bd;
  border-radius: 5px;
}

.tooltip {
  --bs-tooltip-bg: violet;
  --bs-tooltip-color: var(--bs-white);
}

.outerBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(131.83deg, #FFFAFA 0%,     #FFF7F7 99.21%);
  width: 30%;
  text-align: center;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.outerBorder:not(:last-child) {
  margin-right: 100px;
}
.outerBorder::before {
  position: absolute;
  content: "";
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(312.25deg, #aef5bd 0%,     rgba(255, 255, 255, 0) 66.19%);
  z-index: -1;
  border-radius: 10px;
}
@media screen and (max-width: 1200px ) {
  .outerBorder {
    width: 90%;
  }
}
</style>