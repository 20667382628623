<template>
    <div class="container-fluid pb-5  mx-auto outerBorder">
        <div class="innerBorder p-0  mx-auto">
        <div class="row gx-0 pt-4 pb-4 mx-auto mx-lg-0 ">
               
            <h3 class="d-flex justify-content-start ps-5 "> Order # {{ order.id }}</h3>

        <table class="table table-hover mx-auto">
      <thead>
        <tr>
          <th scope="col">Product</th>
          <th scope="col">Price</th>
          <th scope="col">Quantity</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="item in order.items" v-bind:key="item.product.id">
            <td>{{  item.product.name }}</td>
            <td>${{ item.product.price }}</td>
            <td>{{ item.quantity }}</td>
            <td>${{ getItemTotal(item).toFixed(2) }}</td>

        </tr>
      </tbody>
      </table>
    </div>

    </div>
    </div>
        
  
</template>

<script>
export default {
    name:'OrderSummary',
    props: {
        order: Object
    },
    methods:{
        getItemTotal(item) {    
            return item.quantity * item.product.price
        },
        OrderTotalLength(order) {
            return order.items.reduce((acc, curVal) => {
                return (acc += curVal.quantity);
            }, 0);
        },
    }
}
</script>