<template>
  
        <div class="container-fluid">
            <div class="row">
                <img src="../assets/snacks-bg2.jpg" class="bg img-fluid">
              <!--- <a href="https://www.freepik.com/free-photo/frame-made-different-dried-fruits-turkish-delight_4076754.htm#query=arabic%20background%20snack&position=2&from_view=keyword&track=ais_hybrid&uuid=610a3b18-f8cc-45b1-9f05-982eddeb25ab">Image by freepik</a> -->
                
                 <!--<a href="https://www.flaticon.com/free-icons/halal" title="halal icons">Halal icons created by Freepik - Flaticon</a> -->
                <div class="col-6 col-md-6 pb-5">
                    <img  src="../assets/halal3.png" class="halal img-fluid "/>
                </div>

                <div class="col-6 col-md-6  text pb-5 offset-5 mt-md-5">
                    <h2 class="pt-3 pt-sm-0 ps-3"> {{ $t("Carousel.Third.caption1") }}</h2>
                    <p class="ps-3">{{ $t("Carousel.Third.caption2") }}</p>
                    <router-link class="btn btn-success btn-md text-capitalize" :to="{ name : 'Products'}"> {{ $t("Carousel.Third.shopNow") }}</router-link>
                    


                </div>

            </div>
        </div>
       
</template>

<script>

export default {

  name: "carouselSecond"
 
  
};
</script>

<style scoped>

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;

  filter: brightness(0.5);
}

.halal {
  position: relative;
  scale: 0.40;
  pointer-events: none;
  animation: animateBoy 2s ease-in-out;
  margin-top:-5.00rem;
}

@keyframes animateBoy {
    0% {
    right: 0;
    position: absolute;
  }
  
  100% {
    right: 60%;    
    position: absolute;
  }
  
}

.text {
   
position: absolute;
  scale: 1.00;
  pointer-events: none;
  animation: animateText 2s ease-in-out;
}

@keyframes animateText {

    0% {
    bottom: 0;
  }
  100% {
    bottom: 50%;
     
  }
  
}

.text h2 {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.70em;
  font-weight: 500;
  color: white;
  margin-bottom: 5px;
  
}

.text {
  font-size: 1.50em;
  color: white;
  font-weight: 300;
  text-decoration: none;
}

@media screen and (max-width: 992px ){

.bg {
  height: 60vh;
 
}
.text h2 {
  margin-top: 5px;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.50em;
  font-weight: 200;
  color: white;

  
}

.text {
  font-size: 1.25em;
  color: white;
  font-weight: 100;
  text-decoration: none;
  margin-bottom: 40px;
}
    
.halal {
  margin-top: 100px;
  position: relative;
  scale: 0.80;
  pointer-events: none;
  animation: animateBoy 2s ease-in-out;
  width: 200px ;
  margin-top:3.00rem;
}
}

@media screen and (max-width: 576px ){

.bg {
  height: 60vh;
 
}
.text h2 {
  margin-top: 0px;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.00em;
  font-weight: bold;
  color: white;
  margin-bottom: 3px;
  
}

.text {
  font-size: 1.00em;
  color: white;
  font-weight: light;
  text-decoration: none;
}
    
.halal {
  margin-bottom:100px;
  position: relative;
  scale: 0.80;
  pointer-events: none;
  animation: animateBoy 2s ease-in-out;
  width: 200px ;
}

}

@media screen and (max-width: 400px ){

.bg {
  height: 60vh;
 
}
.text h2 {
  margin-top: 10px;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.40em;
  font-weight: bolder;
  color: white;
  margin-bottom: 3px;
  
}

.text {
  font-size: 0.90em;
  color: white;
  font-weight: 200;
  text-decoration: none;
}
    
.halal {
  margin-top: 80px;
  position: relative;
  scale: 0.80;
  pointer-events: none;
  animation: animateBoy 2s ease-in-out;
  width: 200px ;
}
}
</style>