<template>
      <div  
                 v-for="product in allProducts"
                 v-bind:key="product.id">
            
                 <div class="card text-center mx-auto border-0 d-flex" style="width:11.30rem;" v-bind:class="{ disabledOutOfStock: !product.availability }">
            <div class="card-body bg-text-light d-flex flex-column ">

            
         <router-link class="link link-dark link-underline link-underline-opacity-0" :to="product.get_absolute_url" >
          <div class="border border-5 border-white rounded px-3 mb-2 p-3 top-0" style="background-color:	#F0FFF0;position: relative;">
            <img
            :src="product.get_thumbnail" v-bind:class="{ outOfStockClass: !product.availability }"
              
              class="card-image img-fluid mb-2 top-0"
              style="width:140px; height: 110px;mix-blend-mode: multiply;"
            />
          </div>
          

            <div class="card-img-overlay">
              <div  v-if="product.offer && product.availability">
                <span class="justify-content-start d-flex text-white fw-bold" style="font-size: 15px;position:absolute;top:30px;right: 118px;">-{{ product.offerPercentage }}%</span>
                <i class="fa-solid fa-certificate text-danger justify-content-start d-flex" style="font-size: 55px;"></i>
                <div class="border border-light rounded bg-white pe-5" style="margin-top:40px;margin-left:90px;width:15%">
                  <button type="button" class="btn addCartButton text-center "  @click.prevent="itemClicked(product);" :data-bs-target="'addCartModal'+product.id" aria-expanded="false" :aria-controls="'addCartModal' + product.id"  style="position:relative;" >
                    <i class="fa-solid fa-cart-plus justify-content-end text-success"></i>
                  </button>
              </div>
              </div>

              <div class="border border-light rounded bg-white pe-5" v-if="!product.offer && product.availability" style="margin-top:100px;margin-left:90px;width:15%;">
                <button type="button" class="btn addCartButton text-center "  @click.prevent="itemClicked(product);" :data-bs-target="'addCartModal'+product.id" aria-expanded="false" :aria-controls="'addCartModal' + product.id"  style="position:relative;" >
                  <i class="fa-solid fa-cart-plus justify-content-end text-success"></i></button>
              </div>
              
              <div class="border bg-danger text-white fs-4  mt-5" v-if="!product.availability">out of stock</div>
             
             
            </div>
                  <span class="fs-7 text-end text-uppercase text-tertiary justify-content-start d-flex mt-auto " style="font-size: small; font-weight:bold;">{{ product.brand }}</span>
                  <p class="fs-7 text-start text-uppercase">{{ product.name }}</p>
                 
                </router-link> 

                <div class="d-flex  mt-auto" v-if="product.offer">
                  <p class="card-text fs-6 fw-bolder text-danger">{{ product.offerPrice }}€ </p>
                  <p class="card-text fs-6 ms-3 fw-bolder text-decoration-line-through" >{{ product.price }}€ </p> 
                </div>

                <p class="card-text fs-6 fw-bold text-start" v-else>{{ product.price }}€ </p>
                
                 <!--<div class=" mt-auto " style="background-color: #f1f8f1;"> 
                  <router-link  :to="product.get_absolute_url"  type="button" class="btn" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-trigger="hover" data-bs-title="View more details" style="position:relative;"> <i class="fa-regular fa-eye text-success"></i></router-link>
                  <button type="button" class="btn" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-custom-class="custom-tooltip" data-bs-title="Add to favorites" ><i class="fa-regular fa-heart text-success" style="position:relative;"></i> </button>
                
                
                 <button type="button" class="btn addCartButton"   @click.prevent="itemClicked(product);" :data-bs-target="'addCartModal'+product.id" aria-expanded="false" :aria-controls="'addCartModal' + product.id"  style="position:relative;" ><i class="fa-solid fa-cart-plus justify-content-end text-success" ></i></button>
                  
                 
                </div> -->  
        
           
          </div>
          </div>
                </div>
                <template v-if="showModal">
                  <AddToCartModal :modalProduct="modalProduct"></AddToCartModal>
                </template>
</template>

<script>
import AddToCartModal from "@/components/AddToCartModal.vue";
export default {
    name:'ProductsCard',
    components: { AddToCartModal },
    props: {
      allProducts: []
      
    },
    
    data () {
        return {
            showModal: false,
            modalProduct: {}
        }
    },
    methods: {
        itemClicked(item) {
    
            const myModalFirst = document.getElementById('addCartModal' + this.modalProduct.id)
        
            this.modalProduct = item
        
                this.showModal = true  
    
        
 
  }
    }
}
</script>

<style >

.addCartButton i{
  pointer-events: none;
}

.allProducts{
  background:#f1f8f1;;
  font-size: smaller;
  font-weight: bold;
}

.greenClass {
  background:#396828;
}

.disabledOutOfStock{
 
  pointer-events:none;
  filter: opacity(50%);
}



.router-link-active .allProducts {
  background: #396828;
  color: white;
}

.linkallProducts:hover .allProducts{

    background:#396828;
}

  

@media screen and (max-width:576px) {

  .card {
    width: 100%;
    
  }
  
}

@media screen and (min-width:576px) {

.card {
  width: 75%;
  
}

}

@media screen and (max-width:400px) {

.card {
  height: 70%;
 
}
.btn-details::before {
  height: 50%;
  content: 'View';
  padding: 0%;
  
}

.card-title {
  font-size:medium;
}


}

</style>