<template>
   
        <div class="modal fade" :id="'addCartModal' + product.id" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">{{ product.name }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img
            :src="product.get_thumbnail" v-bind:class="{ outOfStockClass: !product.availability }"
              
              class="card-image img-fluid mt-2 mb-2"
              style="width:150px; height: 120px;background-color:#f1f8f1 "
            />
        <p>{{ product.description }}</p>
        <span class="me-4">Please specify the quantity:</span>
        <div class="btn-group my-5" role="group" aria-label="Basic outlined example">
                    <button type="button" class="btn btn-lg btn-outline-success" @click.prevent="removeItem">-</button>
                    <input type="text" style="width: 70px;" class="btn btn-outline-success disabled text-bolder" v-model="quantity">
                    <button type="button" class="btn btn-lg btn-outline-success" @click.prevent="addItem">+</button>
                </div>
      </div>
      <div class="modal-footer">
       
        
        <button type="button" class="btn " data-bs-dismiss="modal">Close</button>
        <button type="button" id="liveToastBtn" class="btn btn-success" @click.prevent="addToCart">Add to Cart</button>
      </div>
    </div>
  </div>


<!--<div id="addCartModal" class="accordion-collapse collapse show" data-bs-parent="#addToCartContainer">
      <div class="accordion-body">
        {{ product.name }}
      </div>
    </div>-->
    </div>

    <div class="toast-container position-fixed bottom-1 end-0 p-3 mb-5" style="margin-top:100px;" >
  <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header  bg-success">
     
      <strong class="me-auto text-white">Rizq cart</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body  bg-success text-white">
      Yout item has been added to the cart!
    </div>
  </div>
</div>

</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min'
export default {

    name: 'AddToCartModal',
    props:{ modalProduct:Object },
    data() {
        return {
            product : this.modalProduct,
            quantity: 1
        }
    },
    mounted() {
    
      const myModalFirst = document.getElementById('addCartModal' + this.modalProduct.id)
  
   
    const myModal = new bootstrap.Modal('#addCartModal' + this.modalProduct.id, {
      backdrop: true
    })

   
      myModal.show()
    
//your modal setup

    const myModalOpen = document.getElementById('addCartModal' + this.modalProduct.id)
    myModalOpen.addEventListener('hidden.bs.modal', event => {
        location.reload()
    })
  
      
   
    },
    methods: {
      removeItem() {
            if(this.quantity > 1){
                this.quantity= this.quantity-1
            }
            else('disply an error message')
        },

        addItem() {
            this.quantity = this.quantity+1
            /*this.product.price = this.product.price * this.quantity*/

        },

        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }
            this.$store.commit('addToCart',item)

            const toastElList = document.querySelectorAll('#liveToast')
            const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl))
            toastList.forEach(toast => toast.show())

      
        
        }
    
    
    }
}
</script>