//Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
let tid: any;
return function (...args: any[]) {
const ctx = self;
tid && clearTimeout(tid);
tid = setTimeout(() => {
callback.apply(ctx, args);
}, delay);
};
};

const _ = (window as any).ResizeObserver;
(window as any). ResizeObserver = class ResizeObserver extends _ {
constructor(callback: (...args: any[]) => void) {
callback = debounce (callback, 20);
super(callback);
}
};

<template>
  <div class="container-fluid pb-lg-0 pb-md-0 " style="margin-bottom:13%; color: pink;">
  
  <NavBar v-bind:allCategories="allCategories">
    <template v-slot:login-my-account="slotProps" >
      <template v-if='$store.state.isAuthenticated'>
        <router-link :to="{ name: 'MyAccount' }" class="nav-link mx-2 text-uppercase" @click="slotProps.clickFunct" ><i class="fa-solid fa-circle-user me-1 d-lg-none d-md-block "></i> <span class="d-md-none d-lg-block"  style=" font-size: 14px;font-weight: 700;">{{ $t("account") }}</span></router-link>
            </template>
            <template v-else>
              <router-link :to="{ name: 'LoginPageTest' }" class="nav-link mx-2 text-uppercase" style=" font-size: 14px;font-weight: 700;" @click="slotProps.clickFunct" 
              >{{ $t("login") }}</router-link
            >
            </template>

    </template>
    
    <template v-slot:cart="slotProps">
    
      <router-link :to="{ name:'CartView' }" class="nav-link mx-2 text-uppercase d-md-flex"  @click="slotProps.clickFunct"><i class="fa-solid fa-cart-shopping me-md-1 d-md-none d-lg-block ms-auto"></i>
        <span style=" font-size: 14px;font-weight: 700;">{{ $t("cart") }}({{ cartTotalLength }})</span>
      </router-link>
       
    </template>
  </NavBar>

  <NavbarSmallScreens>
    <template v-slot:login-my-accountDown >
      <template v-if='$store.state.isAuthenticated'>
        <i class="fa-solid fa-circle-user"></i> 
        <router-link :to="{ name: 'MyAccount' }" class="nav-link mx-2 text-uppercase router mt-1" style="font-size: 0.95em;"> Me</router-link>
      
            </template>
            <template v-else>
              <i class="fa-solid fa-right-to-bracket router"></i>
              <router-link :to="{ name: 'LoginPageTest' }" class="nav-link mx-2 text-uppercase router mt-1" style="font-size: 0.95em;"
              >{{ $t("login") }}</router-link
            >
            </template>

    </template>
    
    <template v-slot:cartDown>
    
      <router-link :to="{ name:'CartView' }" style="font-size: 0.95em;" class="nav-link mx-2 text-uppercase router mt-1" ><i class="fa-solid fa-cart-shopping "></i>
        <span style="font-size: 0.85em;">({{ cartTotalLength }})</span>
      </router-link>
       
    </template>
  </NavbarSmallScreens>

  </div>


  <router-view/>


  <Footer></Footer>

</template>

<script>
//import CarouselHome from './components/CarouselHome.vue';
import LoginPageTest from './views/LoginPageTest.vue';
import HomeView from './views/HomeView.vue';
import NavBar from './components/NavBar.vue'
import axios from 'axios'
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import bootstrap from 'bootstrap/dist/js/bootstrap.min'
import NavbarSmallScreens from './components/NavbarSmallScreens.vue';
import { getCookie } from './main.js'
export default {
  name: 'App',
  components: { NavBar, Header, Footer, NavbarSmallScreens },
  data (){
    return {
      cart: {
        items: []
      },
      allCategories: []

    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')  /*access mutations from store */

    const token = this.$store.state.token
  
    if(token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    }else {
      axios.defaults.headers.common['Authorization'] = ""
    } 
  
    this.$router.beforeEach(function (to, from, next) {
      window.scrollTo(0, 0)
      next();
})
  


},

  async mounted(){
    this.cart = this.$store.state.cart
    this.getAllCategories()
     
  },

  methods:{
    async getAllCategories() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/all-categories/")
        .then((response) => {
          this.allCategories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    }
  },

  computed: {
    cartTotalLength() {
      let totalLength = 0
      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity
      }
      return totalLength
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
 
}
body {
  margin: 0;
  background:white;
}



</style> 

