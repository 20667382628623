<template>
  <nav
    class="navbar navbar-success bg-success navbar-expand-md fixed-top bg-white navbar-light shadow-sm"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row mb-2 ">

            <div class="col-2 mt-4 mt-md-0 d-md-none col-md-0 d-flex">
              <button
                class="navbar-toggler-icon border-0 bg-white border-success mt-2 d-md-none ms-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
             style="width: 30px; height: 30px;" >
              
              </button>

              
            </div>

            <div class="col-8 col-md-6 mx-auto">

             
              <span ref="offcanvasLink" class="navbar-brand mx-auto">
                <img
                  class="smallScreen"
                  src="../assets/rizq_resized.png"
                  alt="rizq"
                />
              </span>
            </div>

            <div class="col-2 col-md-6 mt-4 mt-md-0 mt-lg-4 ">

              <div class="dropdown d-block d-md-none text-end" >
                    <button
                      class="btn btn-success btn-sm dropdown-toggle mt-2 "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    style=" background-color: #396828; font-size:x-small; font-weight: bolder; text-align:start; margin-left: -30.5px;"
                     >
                    {{ languageOption }}
                    </button>
                    <ul class="dropdown-menu  dropdown-menu-end dropdown-menu-sm-start " >
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `de`;changeCountry($event);" value="DE"
                          >DEUTSCH</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `ar`;changeCountry($event);" value="AR"
                          >عربي</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `en`;changeCountry($event)" value="EN"
                          >ENGLISH</a
                        >
                      </li>
                    </ul>
                  </div>
              

             

              <Header class="socialMedia d-none d-md-block "></Header>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-flex mt-2 d-md-none mx-auto">
          <div class="input-group ">
                  <form
                    method="get"
                    action="/search"
                    class="d-flex  w-100"
                    role="search"
                  >
                    
                    <input
                      type="text"
                      class="form-control searchBox  mx-auto "
                      name="query"
                      style="border-color: #396828;"
                      :placeholder="$t('searchPlaceHolder')"
                    />
                    
                    <button
                      class="input-group-text text-white"
                      style="background-color: #396828"
                      ><i class="fa-solid fa-magnifying-glass"></i
                    ></button>
                    
                  </form>
                </div>
        </div>

        <div class="col-12 pt-0 pt-md-5 me-md-5">
          <div class="d-flex py-0 my-0">
           <!-- <div class="pt-3 pe-0 m-1">
              <button
                class="navbar-toggler justify-content-start d-md-none py-0 my-0 toggle"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div class="mx-md-auto my-3 d-md-none d-block me-md-5">
              <div class="input-group">
                <form
                  method="get"
                  action="/search"
                  class="d-flex me-auto"
                  role="search"
                >
                  <span
                    class="input-group-text text-white"
                    style="background-color: #396828"
                    ><i class="fa-solid fa-magnifying-glass"></i
                  ></span>
                  <input
                    type="text"
                    class="form-control"
                    name="query"
                    style="border-color: #396828; width: 100%"
                  />
                  <button
                    class="btn text-white"
                    style="background-color: #396828"
                  >
                    Search
                  </button>
                </form>
              </div>
            </div>-->
          </div>

          <div
            class="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                Main menu
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-start">
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'home' }"
                    class="nav-link mx-2 text-uppercase "
                    @click="closeOffCanvas"
                    >{{ $t("Home") }}</router-link
                  >
                </li>
                <!--<li class="nav-item">
                <router-link :to="{ name:'Products' }" class="nav-link mx-2 text-uppercase " @click="closeOffCanvas">Products</router-link>
                </li> -->

                <li class="nav-item">
                  <router-link
                    :to="{ name: 'AboutView' }"
                    class="nav-link mx-2 text-uppercase"
                    @click="closeOffCanvas"
                    >{{ $t("About") }}</router-link
                  >
                </li>

                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle mx-2 text-uppercase"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ $t("Categories") }}
                  </a>
                  <ul class="dropdown-menu">
                    <li
                      v-for="category in allCategories"
                      v-bind:key="category.id"
                    >
                      <router-link
                        class="dropdown-item"
                        :to="category.get_absolute_url"
                        @click="closeOffCanvas"
                        >{{ category.name }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>

              <div class="mx-auto ms-lg-auto d-none d-xl-block">
                <div class="input-group">
                  <form
                    method="get"
                    action="/search"
                    class="d-flex me-auto"
                    role="search"
                  >
                    <span
                      class="input-group-text text-white"
                      style="background-color: #396828"
                      ><i class="fa-solid fa-magnifying-glass"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control searchBox"
                      name="query"
                      style="border-color: #396828; width: 400px"
                    />
                    <button
                      class="btn text-white"
                      style="background-color: #396828"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>

              <div class="d-none d-md-block d-xl-none mx-lg-auto">
                <div class="input-group">
                  <form
                    method="get"
                    action="/search"
                    class="d-flex me-auto"
                    role="search"
                  >
                    <span
                      class="input-group-text text-white mt-1 mx-md-4 mx-lg-0 mt-lg-0"
                      style="background-color: #396828"
                      ><i class="fa-solid fa-magnifying-glass"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control d-md-none d-lg-block"
                      name="query"
                      style="border-color: #396828; width: 200px"
                      placeholder="Search for products"
                    />
                    <!--- <button class="btn"><i class="fa-solid fa-magnifying-glass"></i></button>
                  <span class="nav-link text-uppercase mt-2 d-md-none d-lg-block">Search</span> -->
                  </form>
                </div>
              </div>

              <ul class="navbar-nav d-flex justify-content-end">
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'home' }"
                    class="nav-link mx-2 text-uppercase"
                    @click="closeOffCanvas"
                    >{{ $t("deals") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <slot name="cart" :clickFunct="closeOffCanvas" class="nav-link mx-2 text-uppercase"> Default</slot>
                </li>
                <li class="nav-item">
                  <slot name="login-my-account" :clickFunct="closeOffCanvas">
                    Default</slot
                  >
                </li>

                <li class="nav-item d-none d-md-block">
                  <div class="dropdown">
                    <button
                      class="btn btn-outline-secondary btn-sm dropdown-toggle mb-3 mx-2 text-uppercase nav-link  "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                     style=" font-size: 14px;font-weight: 700;"
                    >

                      {{ languageOption }}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `de`;changeCountry($event);" 
                          >DEUTSCH</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `ar`;changeCountry($event);"
                          >عربي</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="$i18n.locale = `en`;changeCountry($event);"
                          >ENGLISH</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Header from "./Header.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import NavbarSmallScreens from "./NavbarSmallScreens.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "NavBar",
  props: { allCategories: [] },
  components: { Header, NavbarSmallScreens },
  
    data ()
    {
      return {
       // searchPlaceholder: $t("searchPlaceholder")
       languageOption: 'DEUTSCH'

    }
  },
  methods: {
    closeOffCanvas() {
      let mycanvas = document.getElementById("offcanvasNavbar");
      let openedCanvas = bootstrap.Offcanvas.getInstance(mycanvas);

      if (openedCanvas !== null) {
        openedCanvas.hide();
      }
    },
    changeCountry(event) {
      this.languageOption = event.target.text
      console.log(event.target.text)
    }
  },
};
</script>

<style scoped >
.searchBox {
  color: #7a7a7a;
  width: 1000px;
}
a {
  font-size: 14px;
  font-weight: 700;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.smallScreen {
  margin-top: 0.3rem;
  margin-bottom: -3.5rem;
  margin-right: 25.5rem;
  width: 40%;
}



@media screen and (max-width: 1024px) {
  .centerOnMobile {
    text-align: center;
  }

  .smallScreen {
    margin-top: -3rem;
    margin-bottom: -5.5rem;
    margin-right: 25.5rem;
    width: 60%;
  }
}

@media screen and (max-width: 576px) {
  .centerOnMobile {
    text-align: center;
  }

  .socialMedia {
    position: relative;
    top: 28.5em;
    right: 0em;
    left: 0%;
    bottom: 4%;
  }

  .smallScreen {
    margin-left: -5em;
    width: 30%;
  
    top: 15%;
    margin-right: 50em;
  }
  .searchBox {
  color: #7a7a7a;
  width: 87%;
  margin-right: -100px;
  }
}

@media screen and (width: 540px) {
  .centerOnMobile {
    text-align: center;
  }
  .socialMedia {
    position: fixed;
    top: 15%;
    right: 50%;
    left: 0%;
  }
  .toggle {
    left: 60%;
    right: 40%;
  }

  .smallScreen {
    margin-left: 0.05em;
    width: 70%;
  
    top: 15%;
    margin-right: 3.0em;
  }
}

@media screen and (max-width: 400px) {
  .centerOnMobile {
    text-align: center;
  }

  .smallScreen {
    margin-left: 0.05em;
    width: 100%;
  
    top: 15%;
    margin-right: 5.0em;
  }
  .socialMedia {
    position: relative;
    top: 14em;
    right: 100em;
    left: 0%;
    bottom: 4%;
  }
}

@media screen and (max-width: 430px) and (min-height: 700px) {
  .centerOnMobile {
    text-align: center;
  }

  .searchBox {
    width: 90%;
  }
  .smallScreen {
    margin-left: 1.05em;
    width: 80%;
  
    top: 15%;
    margin-right: 5.0em;
  }
  .socialMedia {
    position: fixed;
    top: 80%;

    left: 7%;
  }

  .toggle {
    left: 70%;
    right: 0%;
  }
}
</style>
