<template>

    <section id="product-detail">
        <div class="container pt-5 pt-md-0 ">
        <div class="row">
            <div class="col-12 pt-5 pt-md-0 col-md-7 ">
                <img :src="product.get_image" alt="" class="product-image">
            </div>
            <div class="col-12 col-md-5 mt-5" style="text-align: justify;">
                <p>Home/Groceries{{ product.get_absolute_url }}</p>
                <h1 class="title my-3">{{ product.name }}</h1>
                
                <div style="color: green; font-weight: bolder;"> {{ this.product.price }} €</div>
                <div class="btn-group my-5" role="group" aria-label="Basic outlined example">
                    <button type="button" class="btn btn-lg btn-outline-success" @click="removeItem">-</button>
                    <input type="text" style="width: 70px;" class="btn btn-outline-success disabled text-bolder" v-model="quantity">
                    <button type="button" class="btn btn-lg btn-outline-success" @click="addItem">+</button>
                </div>
                <span class="btn btn-success btn-lg " @click="addToCart">Add to Cart</span>

                
            </div>
            <div class="col-12">
                <div class="mt-5  d-flex justify-content-around">
                    <a class="link link-success  link-underline link-underline-opacity-0 link-offset-2-hover link-underline-opacity-75-hover fs-5 text-bolder" @click="showDescription = true ; showReviews = false" >Description</a>
                    <a class="link link-success  link-underline link-underline-opacity-0 link-offset-2-hover link-underline-opacity-75-hover fs-5 text-bolder" @click="showDescription = false ; showReviews = true">Reviews</a>
                </div>
                <hr>
                <p class="description text-start" v-if="showDescription">{{ product.description }}</p>
                <p  v-if="showReviews">reviews</p>
            </div>
        </div>
    </div>
    </section>

    <div class="toast text-bg-success bottom-0 left-0" id="cartToast" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                Your Item has been added to cart!
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>

</template>

<script>
import axios from 'axios'
import { Toast } from 'bootstrap/dist/js/bootstrap.min';
import bootstrap from 'bootstrap/dist/js/bootstrap.min'

export default {
    name:'ProductView',
    data(){
        return {
            product: {},
            quantity: 1,
            showDescription : true,
            showReviews : false
            /*price: this.product.price*/
        }
        
    },
    mounted(){
        this.getProduct()
    },
    methods: {
        async getProduct() {
            this.$store.commit('setIsLoading', true)

            const category_slug = this.$route.params.category_slug
            const product_slug = this.$route.params.product_slug
        
            await axios
                .get(`/api/v1/products/${category_slug}/${product_slug}`)
                .then(response => {
                    this.product = response.data

                    document.title = this.product.name + ' | Groceries'
                })
                .catch(error => {
                    console.log(error.response.data)
                })
            this.$store.commit('setIsLoading', false)

        },

        removeItem() {
            if(this.quantity > 1){
                this.quantity= this.quantity-1
            }
            else('disply an error message')
        },

        addItem() {
            this.quantity = this.quantity+1
            /*this.product.price = this.product.price * this.quantity*/
        },

        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }
            this.$store.commit('addToCart',item)

            const toastElList = document.querySelectorAll('.toast')
            const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl))
            toastList.forEach(toast => toast.show())
        
        }
    }
}
</script>

<style scoped>



.product-image {
    width: 70%;
    
}
.product-image:hover {

    width: 100%;
}
</style>
