<template>
  <section id="page-search" class="pt-5">
    <h2 class="pt-5">Search term: "{{ query }}"</h2>

    <h2 class="my-5">Search Products</h2>
    <div class="container">
      <div class="row g-3">
        <!--<div
          class="col-md-3 col-12"
          v-for="product in products"
          v-bind:key="product.id"
        >
          <div class="card text-bg-dark">
            <img
              :src="product.get_thumbnail"
              style="filter: brightness(0.5)"
              class="card-image"
            />
            <div class="card-img-overlay">
              <h5 class="card-title">{{ product.name }}</h5>
              <p class="card-text">{{ product.price }}€</p>
              <router-link :to="product.get_absolute_url">
                <p class="btn btn-success">view details</p>
              </router-link>
            </div>
          </div>
        </div>-->
        <div class="col-6 col-md-4 col-xl-3 gy-3" 
                 v-for="product in products"
                 v-bind:key="product.id">
            
                <div class="card text-bg-dark">
                    <img
                        :src="product.get_thumbnail"
                        style="filter: brightness(0.5)"
                        class="card-image"/>
                    <div class="card-img-overlay">
                        <h5 class="card-title pt-sm-5">{{ product.name }}</h5>
                        <p class="card-text">{{ product.price }}€</p>
                        <router-link :to="product.get_absolute_url">
                            <p class="btn btn-success btn-details"></p>
                        </router-link>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchView",

  data() {
    return {
      products: [],
      query: "",
    };
  },
  mounted() {
    document.title = "Search |Rizq ";

    let url = window.location.search.substring(1);
    let params = new URLSearchParams(url);

    if (params.get("query")) {
      this.query = params.get("query");

      this.performSearch();
    }
  },
  methods: {
    async performSearch() {
      this.$store.commit("setIsLoading", true);
      await axios
        .post("/api/v1/products/search", { query: this.query })
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style scoped>

.btn-details::before {
  content: 'View Details';
}

@media screen and (max-width:576px) {

  .card {
    width: 100%;
    
  }
  
}

@media screen and (min-width:576px) {

.card {
  width: 75%;
  
}

}

@media screen and (max-width:400px) {

.card {
  height: 100%;
 
}
.btn-details::before {
  height: 50%;
  content: 'View';
  padding: 0%;
  
}

.card-title {
  font-size:medium;
}

}
</style>