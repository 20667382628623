import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import LoginPageTest from '@/views/LoginPageTest.vue'
import HomeView from '@/views/HomeView.vue'
import LoginPage from '@/views/LoginPage.vue'
import AboutView from '@/views/AboutView.vue'
import ProductView from '@/views/ProductView.vue'
import CategoryView from '@/views/CategoryView.vue'
import App from '@/App.vue'
import SearchView from '@/views/SearchView.vue'
import CartView from '@/views/CartView.vue'
import SignUp from '@/views/SignUp.vue'
import MyAccount from '@/views/MyAccount.vue'
import CheckOut from '@/views/CheckOut.vue'

import SuccessView from '@/views/SuccessView.vue'
import AllProducts from '@/components/AllProducts.vue'
import SuccessActivationView from '@/views/SuccessActivationView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/cart',
    name: 'CartView',
    component: CartView
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView
  },
  {
    path: '/cart/success',
    name: 'SuccessView',
    component: SuccessView
  },
  {
    path: '/account/activation-success',
    name: 'SuccessActivationView',
    component: SuccessActivationView
  },
  {
    path: '/login',
    name: 'LoginPageTest',
    component: LoginPageTest
  },
  /*{
    //path:'/login',
    name: 'LoginPage',
    component: LoginPage
  },*/
  {
    path:'/aboutus',
    name: 'AboutView',
    component: AboutView
  },
  {
    path:'/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }

  },
  {
    path:'/cart/checkout',
    name: 'CheckOut',
    component: CheckOut,
    meta: {
      requireLogin: true
    }

  },

  {
    path:'/:category_slug/:product_slug/',
    name: 'ProductView',
    component: ProductView,
    
  },
  {
    path:'/:category_slug',
    name: 'CategoryView',
    component: CategoryView,
   
  },
{
  path:'/products',
  name:'Products', 
  component: CategoryView
}

  /*{
    path:'/all-products/',
    name: 'Products',
    component: CategoryView
  }*/

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  if(to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated){
    next({name: 'LoginPageTest', query: { to:to.path } });

  }else {
    next()
  }
})

export default router
