<template>

  <div class="container pt-lg-0">
    <div class="row pt-5  pt-lg-0">
      <carousal></carousal>

      <section>
    <h2 class="my-5">{{ $t("OfferProducts") }}</h2>
   
     <div class="hstack gap-3 mb-0 productScroll"  style="overflow-y:scroll; scrollbar-color:#b5cbbb white ; scroll-behavior: smooth; scrollbar-width:thin;">

      <ProductsCardHome v-bind:allProducts="offerProducts"></ProductsCardHome>
      </div>
  </section>

  
  <categories v-bind:allCategories="allCategories"></categories>

  <section>
    <h2 class="my-5">{{ $t("LatestProducts") }}</h2>
   
     <div class="hstack gap-0 mb-5 productScroll"  style="overflow-y:scroll; scrollbar-color:#b5cbbb white ; scroll-behavior: smooth; scrollbar-width:thin;">

      <ProductsCardHome v-bind:allProducts="latestProducts"></ProductsCardHome>
      </div>
  </section>
    
    
   
    </div>
  </div>
 
  

  <section id="tik-tok" class="pt-md-5">
    <h2 class="text-center my-5">
      {{ $t("Tiktok") }}
    </h2>
    <TikTokWidget></TikTokWidget>
  </section>
</template>

<script>
import carousal from "../components/CarouselHome.vue";
import categories from "../components/Categories.vue";
import TikTokWidget from "../components/TikTokWidget.vue";
import NavBar from "../components/NavBar.vue";
import ProductBox from "../components/ProductBox.vue";
import ProductsCardHome from "@/components/ProductsCardHome.vue";

import axios from "axios";
//import { Tooltip } from "bootstrap";
import ProductView from "./ProductView.vue";

export default {
  name: "HomeView",
  components: { carousal, categories, TikTokWidget, NavBar, ProductBox,ProductsCardHome },
  data() {
    return {
      latestProducts: [],
      allCategories: [],
      offerProducts: []
    };
  },
  mounted() {
    this.getLatestProducts();
    this.getOfferProducts();
    document.title = "Home | Rizq";

    this.getAllCategories();
    

  },
  methods: {
    async getLatestProducts() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/latest-products/")
        .then((response) => {
          this.latestProducts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    async getAllCategories() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/all-categories/")
        .then((response) => {
          this.allCategories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

  async getOfferProducts() {
    this.$store.commit("setIsLoading", true);

    await axios
    .get("api/v1/offer-products")
    .then((response) => {
      this.offerProducts = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    this.$store.commit("setIsLoading", false)
  },
  },
};
</script>

<style scoped>
.productScroll {
  overflow:hidden; 
 
  scrollbar-color:#b5cbbb white ; scroll-behavior: smooth; scrollbar-width:thin;--scrollbar-width-height: 15px;
}
.productScroll:hover{

  overflow:scroll;
 

}

.eye-view {
  display: none;
}
.eye:hover+.eye-view {
  display: block;
  position:absolute;
  background-color: #396828;
  color:white;
  font-size: 13px;
  

}

.custom-tooltip {
  background-color: violet;
  --bs-tooltip-color: var(--bs-white);
}


</style>
