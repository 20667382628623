<template>

    <div class="page-log-in pt-5">
        <section id="logIn" class="pt-5 pt-md-0">
          <div class="container-sm pt-5 pt-lg-0">
            <div class="row gy-5 ">
                <div class="col-12 col-lg-8 pt-md-4 offset-1 mx-auto outerBorder" >
            
                    <div class="innerBorder p-5">
                    
                    <h1 class="pt-2">{{ $t('Login.login') }}</h1>
                   
                  
                    <form @submit.prevent="handleSubmit">
                 
                    <div class="mb-3 pt-2 ">
                        <label class="form-label d-flex justify-content-start">{{ $t('Login.Username') }}</label>
                        <input type="text" class="form-control form-control-md" id="email" v-model="username" required>
                    </div>

                    <div class="mb-3 pt-2 ">
                        <label class="form-label d-flex justify-content-start">{{ $t('Login.Password') }}</label>
                        <input type="password" class="form-control form-control-md" id="password" v-model="password" required>
                    </div>
                    
                    <div class="alert alert-danger" role="alert" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="mb-3 pt-2">
                        <button class="btn btn-success btn-lg w-50">{{ $t('Login.login') }}</button>
                    </div>
                </form>
              </div>
            </div>
             
        

                <div class="col-12 col-lg-4 offset-1 pt-5 mx-5 mx-auto fs-5">
                  <p class="d-flex justify-content-center pt-md-5 fs-4 fw-bolder ">{{ $t('Login.RegisterCaption') }}</p>
                  <P>{{ $t('Login.RegisterText') }}</p>
                  <router-link :to="{ name:'SignUp' }"  class="btn btn-outline-secondary btn-lg">{{ $t('Login.CreateAccount') }}</router-link>
                </div>
            </div>
            </div>
            
        </section>
    </div>
  
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'LoginPageTest',
    data() {
      return {
        username: '',
        password: '',
        errors: []
      }
    },
    mounted() {
      document.title = 'Log In | Rizq'
    },
    methods: {
     
      async handleSubmit() {
        
        axios.defaults.headers.common["Authorization"] = ""

        localStorage.removeItem("token")

        const formData = {
          username: this.username,
          password: this.password
        }
        await axios
          .post("/api/v1/token/login/", formData)
          .then(response => {
            const token = response.data.auth_token

            this.$store.commit('setToken', token)

            axios.defaults.headers.common["Authorization"] = "Token " + token

            localStorage.setItem("token", token)

            const toPath = this.$route.query.to || '/cart'

            this.$router.push(toPath)
          })
          .catch(error => {
            if(error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            }else{
                this.errors.push('Something went wrong. Please try again')

            }
          })
        
        }
    }
  }
  </script>
  
<style >

.innerBorder {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-shadow: 0 20px 40px #aef5bd;
  border-radius: 5px;
}

.outerBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(131.83deg, #FFFAFA 0%,     #FFF7F7 99.21%);
  width: 50%;
  text-align: center;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.outerBorder:not(:last-child) {
  margin-right: 100px;
}
.outerBorder::before {
  position: absolute;
  content: "";
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(312.25deg, #aef5bd 0%,     rgba(255, 255, 255, 0) 66.19%);
  z-index: -1;
  border-radius: 10px;
}

@media screen and (max-width: 576px ) {
  .outerBorder {
    width:90%;
  }
  
}
</style>