<template>
    <section id="success" class="pt-5 my-5">
        <div class="container-fluid text-center border border-success pt-5 my-5">
            <h1>Congratulations!</h1>
            <p class="mt-5"> Your Email has been approved!</p>
            <p>Now you can login to your acccount and make your purchase!</p>
            <router-link class="btn btn-success w-50 btn-lg m-5" :to="{ name:'LoginPageTest' }"> Login Here </router-link>

        </div>
    </section>
</template>

<script>

export default {
    name:'SuccessActivationView',
    mounted() {
        document.title ='Success | Rizq'
    }
}
</script>
