<template>
                <div
                 v-for="product in allProducts"
                 v-bind:key="product.id">
            
                <div class="card text-bg-dark">
                    <img
                        :src="product.get_thumbnail"
                        style="filter: brightness(0.5)"
                        class="card-image"/>
                    <div class="card-img-overlay">
                        <h5 class="card-title pt-sm-5">{{ product.name }}</h5>
                        <p class="card-text">{{ product.price }}€</p>
                        <router-link :to="product.get_absolute_url">
                            <p class="btn btn-success btn-details"></p>
                        </router-link>
                    </div>
                </div>
                </div>
    
</template>

<script>
import axios from 'axios';

export default {
    name:'AllProducts',
    props: { allProducts: [] },
    data (){

    },
    
}
</script>