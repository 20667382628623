<template>
  <div class="container-sm pt-5 pt-md-0 pt-xxl-0">
    <div id="carouselRizq" class="carousel slide" data-bs-ride="carousel" > <!--data-bs-ride="carousel"-->
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselRizq"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselRizq"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselRizq"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselRizq"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      ></button>
    </div>
    <!-- <a href="https://iconscout.com/lottie-animations/delivery-on-scooter" class="text-underline font-size-sm" target="_blank">Delivery On Scooter</a> by <a href="https://iconscout.com/contributors/lifeofgogoi" class="text-underline font-size-sm" target="_blank">Porag Gogoi</a>-->
    <div class="carousel-inner pt-md-3 pt-xxl-0"  >

      <div class="carousel-item active c-item-zero bg-success-subtle">
       
          <div class="carousel-caption row mt-5 mt-md-5" >
            <div class="col-6 col-md-6 text-center text-success mt-5 mt-md-5" >
                <h2  class="text mt-5 mt-md-5 mt-lg-0  ">{{ $t("Carousel.First.caption1") }}</h2>
                <p class="textP  w-100">{{ $t("Carousel.First.caption2") }}</p>

                <router-link class="btn btn-success btnShop btn-md text-capitalize" :to="{ name:'Products'}"> {{ $t("Carousel.First.deals") }} </router-link>
            </div>

            <div class="col-6 col-md-6">
              <img src="../assets/rizqMarktBag.webp" class="img-fluid zero-img pt-5 ps-5 "  alt="veggies">
            </div>
          </div>
      </div>

      <div class="carousel-item c-item">
        <img src="../assets/Veggies.png" class="d-block w-100 c-img img-fluid" alt="veggies">
        <div class="carousel-caption top-0 mt-md-5 mt-0" >
          <h1 class="text1 text-capitalize mt-5">{{ $t("Carousel.Second.caption1") }}</h1>
          <h3 class="textP1">{{ $t("Carousel.Second.caption2") }}</h3>
          <router-link class="btn btn-success btn-md text-capitalize  " :to="{ name:'Products'}">{{ $t("Carousel.Second.shopNow") }} </router-link>
        </div>
      </div>
      <div class="carousel-item c-item">
    
        <CarouselSecond></CarouselSecond>
      </div>
      <div class="carousel-item  c-item">
   
        <CarouselThird></CarouselThird>
        
      </div> 
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselRizq"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselRizq"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  </div>
  
</template>

<script>
import CarouselThird from './CarouselThird.vue';
import CarouselSecond  from './CarouselSecond.vue';
export default {

  name: "carousel",
  components: {CarouselThird, CarouselSecond},
  /*data() {
    return {
      firstSlideLabel: "Grocery store Shopping",
      placeholderFirstSlide:$t("Carousel.Second.caption2"),
      secondSlideLabel: "Free delivery for orders over 50€ in Hamburg!",
      placeHolderSecondSlide:
        "Order our products online at the comfort of your home and we will deliver it for you!",
        zeroSlideLabel: "Welcome to our  Grocery market! ",
      placeHolderZeroSlide:
        "At Rizq Markt, we believe in the importance of community, quality, and trust."
    };
  },*/
};
</script>

<style scoped>
.c-item {
  height: 300px;
  /*background-color: black; */
}

.c-item-zero {

height: 300px;
}

.c-img {
  height: 100%;
 
  /*opacity: 0.7;*/
  filter: brightness(0.7);
  object-fit:cover;

}
.c-video {
  width: 100%;
  height: 100%;
  filter: brightness(0.8)

}

.zero-img {
  margin-top: 20px;
  width: 60%;
}
.text h2 {
  text-align: center;
  font-size: 2.0em;
  font-weight: bolder;
}

.textP {
  font-size: 1.50em;
 
  font-weight: 300;
  text-decoration: none;
}
.textP1 {
  font-size: 1.50em;
 
  font-weight: 300;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {

  .c-item-zero {

height: 300px;
}
.c-img div h2 {

font-size: small;
font-weight: bolder;
}


  .zero-img {
   margin-top: 70px;
    width:auto;
    height: 70%;
    margin-right: 0%;
    object-fit:cover;
   
   
  }
  .text h2 {
  text-align: center;
  font-size: 1.0em;
  font-weight: bold;
}

.textP {
  font-size: 0.60em;
 
  font-weight: 200;
  text-decoration: none;
}

.textP {
  font-size: 0.60em;
 
  font-weight: 200;
  text-decoration: none;
}
  
}

@media screen and (max-width: 576px) {

  .c-item {
    height: 290px;
  }

.c-item-zero {

height: 290px;
}
.c-img div h2 {

font-size: small;
font-weight: lighter;
}


.zero-img {
 margin-top: 70px;
height:60%;
  width:750px;
  margin-right: 0%;
 
 
}
.text{
text-align: center;
font-size: 0.90em;
font-weight: bold;
}

.textP {
font-size: 0.80em;

font-weight: light;
text-decoration: none;
}

.textP1 {
font-size: 0.80em;

font-weight: 350;
text-decoration: none;
}


}




</style>
