<template>
     <nav class="navbar fixed-bottom bg-white navbar-light shadow-sm d-flex d-md-none text-black">
      <div class="container-fluid">
   
          
      <div class="d-flex flex-row mx-auto">
   
                <div class="p-3 fs-7 ">
                  <i class="fa-solid fa-house-user"></i>
                  <router-link :to="{ name: 'home' }" class="nav-link mx-2 text-uppercase active router  mt-1">HOME</router-link>
                </div>
              <div class="p-3 fs-7">
                <i class="fa-solid fa-percent"></i>
                  <router-link :to="{ name:'Products' }" class="nav-link mx-2 text-uppercase router mt-1">Deals</router-link>
                </div>

                <div class="p-3 fs-7 ">
                  <slot name="cartDown"> </slot>
                  <span class="navlink mx-2 text-uppercase mt-1 router">Cart</span> 
                  
                </div>
                <div class="p-3 fs-7 ">
                  
                  <slot name="login-my-accountDown"> Default </slot>
                  
                </div>

        </div>

      </div>
    </nav> 
</template>

<style scoped>
@media  screen and (max-width:576px) {
.router {
    font-size: 0.95em;
}
    
}
</style>