<template>
     <tr>
        
        <td>
            <img class="" :src="item.product.get_thumbnail" width="50%">
        </td>
        <td> 
            <router-link class="link link-success my-3 mb-3 text-start text-capitalize" :to="item.product.get_absolute_url">{{ item.product.name }}</router-link> 
            </td>
            <td class="pt-4 d-flex flex-column" v-if="item.product.offer">
                <span class="text-danger mt-4">€{{ item.product.offerPrice }}</span>
                <span class="mb-5 text-decoration-line-through">€{{ item.product.price }}</span>
            </td>
        <td class="mt-2" v-else>€{{ item.product.price }}</td>
        <td>
            <div class="btn-group p-2" role="group" aria-label="Basic outlined example">
                    <button type="button" class="btn btn-outline-success" @click="decrementQuantity(item)">-</button>
                    <input type="text" style="width: 50px;" class="btn btn-outline-success disabled" v-model="item.quantity">
                    <button type="button" class="btn btn-outline-success" @click="incrementQuantity(item)">+</button>
                </div>
        </td>
        <td class="pt-4 d-flex flex-column" v-if="item.product.offer">
                <span class="text-danger mt-4">€{{ getItemTotalOfferPrice(item).toFixed(2) }}</span>
                <span class="mb-5 text-decoration-line-through">€{{ getItemTotal(item).toFixed(2) }}</span>
            </td>
        <td class="pt-4" v-else>€{{ getItemTotal(item).toFixed(2) }}</td>
        <td class="pt-3 "><button class="link link-danger me-3 p-2 border border-0 bg-success-subtle rounded-circle" alt="delete" @click="removeFromCart(item)"><i class="fa-solid fa-trash"></i></button></td>
      </tr>
     
    
</template>

<script>
export default {
    name:'CartItem',
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem
        }
    },
    methods: {

        getItemTotalOfferPrice(item) {
            /*const item = this.items.filter((item) => item.) */ 
            if(item.product.offer) {
                const offerTotalprice = this.item.quantity * item.product.offerPrice
                return parseFloat( offerTotalprice.toFixed(2))
            }    
            
        },

        getItemTotal(item) {
            
            const price = this.item.quantity * item.product.price
            return parseFloat(price.toFixed(2))
        },
        decrementQuantity(item) {
            item.quantity -=1

            if(item.quantity == 0){
                this.$emit('removeFromCart',item)
            }

            this.updateCart()
        },
        incrementQuantity(item) {
            item.quantity +=1

            this.updateCart()
        },
        updateCart(){
            localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
        },
        removeFromCart(item) {
            this.$emit('removeFromCart', item)

            this.updateCart()
        }
    }
}
</script>
<style scoped>
tr {
      height: 100px;
    }
</style>