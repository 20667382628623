
<template>
  <section>
  <div class="container-fluid">
      <div class="row bg-success-subtle">
          <!--<img src="../assets/hamburg_cropped_2.jpg" class="bg img-fluid"> -->
        <!--- <a href="https://www.freepik.com/free-photo/frame-made-different-dried-fruits-turkish-delight_4076754.htm#query=arabic%20background%20snack&position=2&from_view=keyword&track=ais_hybrid&uuid=610a3b18-f8cc-45b1-9f05-982eddeb25ab">Image by freepik</a> -->
          
           <!--<a href="https://www.flaticon.com/free-icons/rizqBox" title="rizqBox icons">rizqBox icons created by Freepik - Flaticon</a> -->
          <div class="col-6 col-md-6 ">
              <img  src="../assets/rizq_box.jpg" class="rizqBox img-fluid pt-0"/>
          </div>

          <div class="col-6 col-md-6 text pb-5 offset-5 me-5 ">
              <h2 class="pt-3 pt-sm-0 ps-4 text-success">{{ $t("Carousel.Fourth.caption1") }}</h2>
              <p class="ps-3 text-success">{{ $t("Carousel.Fourth.caption2") }}</p>
              <router-link class="btn btn-success btn-md text-capitalize" :to="{ name : 'Products'}"> {{ $t("Carousel.Fourth.shopNow") }}</router-link>
              

              <img src="../assets/Smiley-delivery-man.png" class="boy img-fluid " >
          </div>

      </div>
  </div>
  <!--<CarouselThirdMobile class="d-block d-md-none"></CarouselThirdMobile>-->
</section>
</template>



<style scoped>

section {
  height: 350px;
}
.bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 70%;
object-fit: cover;

filter: brightness(0.5);
}

.rizqBox {
position: relative;
scale: 0.50;
pointer-events: none;

margin-top:-9.50rem;
}


.text {
 
position: absolute;
scale: 1.00;


}



.text h2 {
position: relative;
width: 100%;
text-align: center;
font-size: 1.50em;
font-weight: 500;
color: white;
margin-top: 70px;

}

.text {
font-size: 1.30em;
color: white;
font-weight: 300;
text-decoration: none;
}
section .boy {
position: absolute;
scale: 0.50;
pointer-events: none;
margin-bottom: 150px;
animation: animateBoy 15s linear  ;
margin-top: -315px;
margin-left:-50px;

}

@keyframes animateBoy {
0% {
  transform: translateX(calc(100% + 100vw));
}
50% {
  transform: translateX(calc(-100% - 100vw));
}
50.01% {
  transform: translateX(calc(-100% - 100vw)) rotateY(180deg);
}
100% {
  transform: translateX(calc(100% + 100vw)) rotateY(180deg);
}
}

@media screen and (max-width: 992px ){

.bg {
height: 60vh;

}
.text h2 {
margin-top: 5px;
position: relative;
width: 100%;
text-align: center;
font-size: 1.50em;
font-weight: 200;
color: white;


}

.text {
font-size: 1.25em;
color: white;
font-weight: 100;
text-decoration: none;
margin-bottom: 40px;
}
  
.rizqBox {
margin-top: 100px;
position: relative;
scale: 0.80;
pointer-events: none;
width: 200px ;
margin-top:3.00rem;
}
}

@media screen and (max-width: 576px ){



  .bg {
height: 60vh;

}
.text h2 {
margin-top: 0px;
position: relative;
width: 100%;
text-align: center;
font-size: 1.00em;
font-weight: bold;
color: white;
margin-bottom: 3px;

}


.text {
font-size: 0.85em;
color: white;
font-weight:light;
text-decoration: none;
}
  
.rizqBox {
margin-bottom:0px;
position: relative;
scale: 1.00;
pointer-events: none;

width: 300px ;
}

section .boy {
position: absolute;
scale: 1.00;
pointer-events: none;

animation: animateBoy 15s linear  ;
margin-top: -100px;
margin-left:-300px;

}
@keyframes animateBoy {
0% {
  transform: translateX(calc(100% + 100vw));
}
50% {
  transform: translateX(calc(-100% - 100vw));
}
50.01% {
  transform: translateX(calc(-100% - 100vw)) rotateY(180deg);
}
100% {
  transform: translateX(calc(100% + 100vw)) rotateY(180deg);
}
}
}
@media screen and (max-width: 400px ){

section {
height: 270px;

}
.text h2 {
margin-top: 30px;
position: relative;
width: 100%;
text-align: center;
font-size: 0.90em;
font-weight: bold;
color: white;
margin-bottom: 3px;

}


.text {
font-size: 0.80em;
color: white;
font-weight:light;
text-decoration: none;
}
  
.rizqBox {
  margin-top:50px;
margin-bottom:50px;
position: relative;
scale: 1.00;
pointer-events: none;

width: 300px ;
}

section .boy {
position: absolute;
scale: 1.00;
pointer-events: none;
margin-bottom: 150px;
animation: animateBoy 15s linear  ;
margin-top: -100px;
margin-left:-260px;

}
}

</style>




<!--<template>
    <section>
      
        <div class="container d-none d-md-block">
          <div class="row">
            <div class="col-4 col-md-8 mb-5"  >
              
             

              <div class="carouselText mt-0 mt-md-5 mx-auto">
            <div class="text" >
              <h2>{{ $t("Carousel.Fourth.caption1") }}</h2>
              <p>{{ $t("Carousel.Fourth.caption2") }}</p>
              <router-link class="btn btn-success btn-sm mt-3" :to="{ name : 'Products'}"> {{ $t("Carousel.Fourth.shopNow") }}</router-link>
            </div>
            
            <img src="../assets/Smiley-delivery-man.png" class="boy img-fluid " >
      </div>
              

            </div>
            <div class="col-8 col-md-4  ">
              
              <img src="../assets/rizq_box.jpg" class="img-fluid third-image">
            </div>
          </div>
        </div>
       
        
        <CarouselThirdMobile class="d-block d-md-none"></CarouselThirdMobile>
    </section>
</template>-->

<!--<script>
import CarouselThirdMobile from './CarouselThirdMobile.vue';

export default {

  name:'CarouselThird',
  components: { CarouselThirdMobile }

}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.third-image {
  margin-top: 60px;
  width: 100%;

}
section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

section .bg {
  position: absolute;
  bottom: 100px;
  left: 200px;
  width: 30%;
  height: 40%;
  object-fit: cover;
  pointer-events: none;
  filter: brightness(70%);
}

section .boy {
  position: absolute;
  scale: 0.50;
  pointer-events: none;
  margin-top:-50px;
  margin-left:350px;
  animation: animateBoy 35s linear ;
  
}

@keyframes animateBoy {
  0% {
    transform: translateX(calc(100% + 100vw));
  }
  50% {
    transform: translateX(calc(-100% - 100vw));
  }
  50.01% {
    transform: translateX(calc(-100% - 100vw)) rotateY(180deg);
  }
  100% {
    transform: translateX(calc(100% + 100vw)) rotateY(180deg);
  }
}

.carouselText {
  position: relative;
  padding: 10px;
  top: 50px;
  right:-70px;
  background-image: url('../assets/hamburg_cropped_2.jpg');

  backdrop-filter: opacity(100%);
  
  width: 650px;
  height:500px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 20px;
  
}

.carouselText h2 {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.80em;
  font-weight: 500;
  color: white;
  margin-bottom: 5px;
}

.carouselText .text {
  font-size: 1.00em;
  color: white;
  font-weight: 300;
  height: 155px;
  text-decoration: none;
  backdrop-filter: blur(7px);
  margin-top:180px;
  border: 2px solid #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1000px ){

  div .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40vh;
  object-fit: cover;
  pointer-events: none;
}


.carouselText .text {
  font-size: 1.00em;
  color: white;
  font-weight: 100;
  text-decoration: none;
}
    
section .boy{
  margin-top: 50px;

  scale: 0.40;
  pointer-events: none;

}

.carouselText {
  margin-top: 0px;
  padding: 10px;
  width: 200px;
  gap: 20px;
 
 
}
.carouselText h2 {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1.25em;
  font-weight: 300;
  color: white;
 
}

}

@media screen and (max-width: 576px ){

  section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.third-image {
  margin-top: 100px;
  width: 600px;
  height: 100%;

}

section .bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 40vh;
object-fit: cover;
pointer-events: none;

}
.text h2 {
margin-top: 15px;
position: relative;
width: 100%;
text-align: center;
font-size: 1.00em;
font-weight: 200;
color: white;
margin-bottom: 3px;

}


.text p {
  font-size: 0.75em;
}
  
section .boy{
margin-top: 0px;
scale: 0.60;
pointer-events: none;

}

.carouselText {
  background-image: none;
  margin-top:0%;
  padding: 10px;
  width: 250px;
  height: 300px;
  gap: 20px;
  

}

}

</style>-->