<template>
  <section id="category-products" class="pt-5 pt-md-0">
    <!--<h2 class="my-5 pt-md-0">Products</h2>-->
    <div class="container-sm pt-5 pt-md-0">
      

      <div class="hstack gap-3 mb-5 categoryScroll ">
     

        <div class="p-2 linkallProducts" >
            <router-link class="link link-dark link-underline link-underline-opacity-0 link-opacity-75 link-opacity-100-hover " :to="{ name:'Products'}" v-on:click="allProductsBtn = 1; activeButton = 0 "><p class="p-3 allProducts text-uppercase" v-bind:class="{ greenClass: allProductsBtn == 1 }" style="width: 200px;height:50px">  All Products</p></router-link>
        </div>
        <div class="p-2 " id="collapseList"
            v-for="category in allCategories"
            v-bind:key="category.id"
          >
        
            <router-link class="link link-dark link-underline link-underline-opacity-0 link-opacity-75 link-opacity-100-hover " :to="category.get_absolute_url"  v-on:click=" activeButton = category.id; allProductsBtn = 0"><p class="allProducts p-3 text-uppercase" v-bind:class="{ greenClass :activeButton == category.id }" style="width: 200px;height:50px;">  
              {{ category.name }}</p>
            </router-link>
        </div>
      </div>
 
      <div class="row">
        <template v-if="displayAllProducts">
          <ProductsCard v-bind:allProducts="allProducts"></ProductsCard>
               
        </template>
        <template v-else>

          <ProductsCard v-bind:allProducts="category.products"></ProductsCard>
          
        </template>
        
      </div>
    </div>

  </section>
  


</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import AllProducts from "@/components/AllProducts.vue";
import ProductView from "./ProductView.vue";
import AddToCartModal from "@/components/AddToCartModal.vue";
import ProductsCard from "@/components/ProductsCard.vue";

import bootstrap from 'bootstrap/dist/js/bootstrap.min'

export default {
  name: "CategoryView",
  components: { AllProducts, ProductView, AddToCartModal, ProductsCard },
  data() {
    return {
      category: {
        products: [],
      },
      allCategories: [],
      allProducts: [],
      displayAllProducts: false,
      allProductsBtn: 0,
      activeButton: 0,
      showModal: false,
      modalProduct: {},
      cartLinkClicked: false,
      
    };
  },
  mounted() {
    this.getCategory();
    this.getAllCategories();
   
    
  },
  watch: {
    $route(to, from) {
      if (to.name === "CategoryView") {
        this.getCategory();
        this.displayAllProducts = false
      }
      else if(to.name === "Products") {
        this.displayAllProducts = true
        this.getAllProducts();
        
      }
    },
  },
  methods: {
    async getCategory() {
      const categorySlug = this.$route.params.category_slug;
      this.$store.commit("setIsLoading", true);

      if (categorySlug) {
        await axios
        .get(`/api/v1/products/${categorySlug}/`)
        .then((response) => {
          this.category = response.data;

          document.title = this.category.name + " | Rizq";
        })
        .catch((error) => {
          console.log(error.response.data);

          toast({
            message: "Something went wrong. Please try again",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            psoition: "bottom-right",
          });
        });
      }
      else {
        this.displayAllProducts = true
        this.getAllProducts()

      }
    
      this.$store.commit("setIsLoading", false);
    },

    async getAllCategories() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/all-categories/")
        .then((response) => {
          this.allCategories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

  async getAllProducts(){
            this.$store.commit("setIsLoading", true);

            await axios
                .get("api/v1/all-products/")
                .then((response) => {
                    this.allProducts = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data)
                });
            this.$store.commit("setIsLoading", false)
        },

        itemClicked(item) {
    
      const myModalFirst = document.getElementById('addCartModal' + this.modalProduct.id)
    
    this.modalProduct = item
     
          this.showModal = true  
      
          
   
    }
    
    
  
 
      },
};
</script>
<style scoped>


.categoryScroll {
  overflow:hidden; 
 
  scrollbar-color:#b5cbbb white ; scroll-behavior: smooth; scrollbar-width:thin;
}
.categoryScroll:hover {

  overflow:scroll;
 

}

.addCartButton i{
  pointer-events: none;
}

.allProducts{
  background:#f1f8f1;;
  font-size: smaller;
  font-weight: bold;
}

.greenClass {
  background:#396828;
}

.disabledOutOfStock{
 
  pointer-events:none;
  filter: opacity(50%);
}



.router-link-active .allProducts {
  background: #396828;
  color: white;
}

.linkallProducts:hover .allProducts{

    background:#396828;
}

  

@media screen and (max-width:576px) {

  .card {
    width: 100%;
    
  }
  
}

@media screen and (min-width:576px) {

.card {
  width: 75%;
  
}

}

@media screen and (max-width:400px) {

.card {
  height: 100%;
 
}
.btn-details::before {
  height: 50%;
  content: 'View';
  padding: 0%;
  
}

.card-title {
  font-size:medium;
}


}

</style>
