//import Vue from 'vue';
import { createApp } from 'vue'
//import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n'


import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.min.js'
//import 'bootstrap-vue/dist/bootstrap-vue.min.js'

//Vue.use(BootstrapVue)
//Vue.use(IconsPlugin)
//axios.defaults.baseURL = "http://127.0.0.1:8000/"
axios.defaults.baseURL = 'https://api.rizqonline.de' 



createApp(App).use(store).use(router, axios).use(i18n).mount('#app')
