<template>

  <section id="categories" class="pt-md-0">
    <h2 class="text-center my-5">{{ $t("Categories") }}</h2>

      
      <div class="container-sm ">
      <div class="row g-2 ms-0 ">
        <div
          class="col-6 col-md-3 col-lg-2"
          v-for="category in allCategories"
          v-bind:key="category.id"
        >

          <div class="card border-light rounded text-center h-100 mx-auto border-4 border-opacity-100" style="width:10rem;background-color: #FAF9F6;">
            <div class="card-body bg-text-light d-flex flex-column">

              <router-link class="link link-success link-underline link-underline-opacity-0" :to="category.get_absolute_url">
                <p class="fs-6">{{ category.name }}</p>
              </router-link>
            <img
              :src="category.get_image"
              
              class="card-image img-fluid mt-auto w-100"
              style="mix-blend-mode: multiply;"
            />
    
            </div>
          </div>
      </div>
    </div>
      </div>
  </section>
</template>

<script>

export default {
    name:'categories',
    props: {
      allCategories: []
    }
}
</script>

<style scoped>
.text {
  margin-top: 10px;
  min-height: 100%;
  margin-bottom:-50px;
}
.footer{
          
          text-align: center;
        
          height: 50px;
      }
  
      .img {
        margin-top:0%;
        width: 250px;
        height: 50%;
        margin-bottom: -1.00rem;
      }

</style>
