<template>
    <div class="page-sign-up pt-5">
        <section id="signUp" class="pt-5 pt-md-0">
            <div class="container-sm pt-5 pt-md-0">
            <div class="row  gy-5">
                <div class="col-12 col-lg-8 pt-md-4 offset-1 mx-auto outerBorder">

                    <div class="innerBorder p-5">
                
                    <h1 class="pt-4">Sign up</h1>
                   

                    <form @submit.prevent="submitForm">
                    <div class="mb-3 pt-3 ">
                        <label  class="form-label d-flex justify-content-start">Username</label>
                        <input type="text" class="form-control form-control-md" id="username" v-model="username" required>
                    </div>

                    <div class="mb-3 pt-2">
                        <label class="form-label d-flex justify-content-start">Email address</label>
                        <input type="email" class="form-control form-control-md" id="email" v-model="email" required>
                    </div>

                    <div class="mb-3 pt-2">
                        <label class="form-label d-flex justify-content-start">Password</label>
                        <input type="password" class="form-control form-control-md" id="password" v-model="password" required>
                    </div>

                    <div class="mb-3 pt-2">
                        <label class="form-label d-flex justify-content-start">Repeat Password</label>
                        <input type="password" class="form-control form-control-md" id="re-password" v-model="re_password" required>
                    </div>
                    
                    <div class="alert alert-danger" role="alert" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="pt-2 pb-4 pb-md-0">
                        <button class="btn btn-success btn-lg w-50">Sign Up</button>
                    </div>

                    <div class="mt-3 text-success fs-5 fw-bolder" v-if="successMessage">
                        <p>{{ successMessage }}</p>
                    </div>
                </form>
            </div>
                </div>

                <div class="col-12 col-lg-4 offset-1 pt-5 mx-5 mx-auto fs-5">
                  <p class="d-flex justify-content-center pt-md-5 fw-bolder fs-4">Already Have an Account?</p>
                  <p>If you’re already a member of the Rizq Markt community, welcome back! Simply log in to access your account and enjoy a seamless shopping experience.</p>
                  <router-link :to="{name:'LoginPageTest'}"  class="btn btn-outline-secondary btn-lg">Login</router-link>
                </div>
            </div>
            
        </div>
        <div class="toast-container position-fixed bottom-1 end-0 p-3 mb-5" style="margin-top:100px;" >
  <div id="liveToastSignUp" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header  bg-success">
     
      <strong class="me-auto text-white">Rizq cart</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body  bg-success text-white">
      Yout item has been added to the cart!
    </div>
  </div>
</div>
        </section>

   
  
    </div>
</template>

<script>
import axios from 'axios'
import bootstrap from 'bootstrap/dist/js/bootstrap.min'

export default {
    name: 'SignUp',
    data(){
        return{
            username: '',
            email: '',
            password: '',
            re_password: '',
            errors: [],
            successMessage: ''
        }
    },
    methods: {

        submitForm() {
            
            this.errors =[]
           
            if(this.password.length <=6 ) {
                this.errors.push('The password is too short! It should have atleast 6 characters!')
            }
            console.log(this.errors)
            if(!this.errors.length){
                const formData = {
                    username: this.username,
                    email: this.email,
                    password: this.password,
                    re_password: this.re_password
                }

                axios
                    .post("/api/v1/users/", formData)
                    //.post('/accounts/signup/', formData)
                    .then(response => {
                        
                        //(this.$router.push('/login')
                        console.log(response)
                        if (response.status === 201 ){
                            this.successMessage = 'Registration successful. An activation email has been sent to your email address.Please verify your email to login and buy! '
                            console.log('User registered. Please verify your email.')
                            const toastElList = document.querySelectorAll('#liveToastSignUp')
                            const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl))
                            toastList.forEach(toast => toast.show())
                        }
                        

                    })
                    .catch(error => {
                        if(error.response){
                            for(const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                         
                        }else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')

                        }
                    
                    })

                   
            }

        },
      
    }
}
</script>

<style scoped>

.innerBorder {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-shadow: 0 20px 40px #aef5bd;
  border-radius: 5px;
}

.outerBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(131.83deg, #FFFAFA 0%,     #FFF7F7 99.21%);
  width: 50%;
  text-align: center;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.outerBorder:not(:last-child) {
  margin-right: 100px;
}
.outerBorder::before {
  position: absolute;
  content: "";
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(312.25deg, #aef5bd 0%,     rgba(255, 255, 255, 0) 66.19%);
  z-index: -1;
  border-radius: 10px;
}

@media screen and (max-width: 1200px ) {
  .outerBorder {
    width:90%;
  }
  
}
</style>