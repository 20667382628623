<template>
  <section id="tik-tok" class="">
    <div class="container-sm pt-5 pt-lg-0">
      <div class="row mt-5 mt-lg-0 mb-5 bg-body-tertiary">

        <div class="col-12">
          <img src="../assets/Rizq_box_about_resized.jpg" class="mb-0" style="width: 100%; height: 85%;">
        </div>
        <div class="col-12 col-md-4 col-lg-4 mt-3 pt-lg-0">
        
        <i class="fa-solid fa-people-group" style="font-size: 30px;"></i>
        <h1>{{ $t("AboutPage.who") }}</h1>
        
        <h5 style="text-align: justify;">{{ $t("AboutPage.whoText") }}</h5>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mt-3">
          <i class="fa-solid fa-bullseye" style="font-size: 30px;"></i>
        <h1>{{ $t("AboutPage.What") }}</h1>
        <h5 style="text-align: justify;">{{ $t("AboutPage.WhatText") }}</h5>
        </div>

        <div class="col-12 col-md-4 col-lg-4 mt-3  mb-5">
          <i class="fa-regular fa-thumbs-up" style="font-size: 30px;"></i>
          <h1>{{ $t("AboutPage.Follow") }}</h1>

            <h5 style="text-align: justify;">

              {{ $t("AboutPage.FollowText") }} </h5>
              <a href="https://www.facebook.com/profile.php?id=61560149094776">
                <i class="fa-brands fa-square-facebook me-3" style="font-size: 30px;"></i>
              </a>
              <a href="https://www.tiktok.com/@rizq.markt">
                <i class="fa-brands fa-tiktok" style="font-size: 30px;color: black;"></i>
              </a>

        </div>
   
      </div>
    <div class="row">
        
   
    <div class="col-12">
      <div class="col w-100" style="margin-right: 100%; margin-left: 0%;">
          <TikTokWidget></TikTokWidget>
        </div>

    </div>
  </div>
    </div>
 
  </section>
  
</template>


<script>
import TikTokWidget from '@/components/TikTokWidget.vue';
import Header from '@/components/Header.vue';

export default{
  name:'AboutView',
  components:{TikTokWidget, Header},
  mounted() {
    document.title = 'About | Rizq'
  }
  }

</script>

<style scoped>
h1 {

  font-family:fantasy;

}
</style>


