<template>
      <footer class="text-center text-lg-start" style="margin-top: 20%; background-color:#77b477 " >
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">

        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase text-white pb-3">{{ $t('Footer.AboutRizq')}}</h5>

        <p class="text-body" style="text-align: justify;font-size: large">
          {{ $t('Footer.AboutText')}}
        </p>

        <div>
            <i class="fa-solid fa-location-dot me-lg-4"></i>
            <span class="contact"> Bramfelder Ch 97c/97e, 22177 Hamburg </span>

        </div>

        <div class="pt-4 ms-md-0">
            <i class="fa-solid fa-phone me-lg-4 text-warning "></i>
            <span class="text-warning contact"> 01520 2053000 </span>

        </div>

        <div class="pt-4">
            <i class="fa-regular fa-paper-plane me-lg-4 text-warning"></i>
            <span class="text-warning contact"> rizqmarkt@gmail.com </span>

        </div>
            
        <div class="pt-4 mx-auto my-3 d-sm-block d-xs-block">
          <div class="input-group">
            <input type="text" class="form-control border-warning" style="color:#7a7a7a" :placeholder="$t('Footer.ShareEmail')">
            <button class="btn btn-warning text-white"><i class="fa-regular fa-paper-plane"></i></button>
          </div>
        </div>
        <Header class="socialMedia d-md-none d-block"></Header>
      </div>
       
    
   
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ">

        <div class="mx-lg-5">
            <h5 class="text-uppercase text-white pb-3">{{ $t('Footer.Information')}}</h5>
            
            <router-link class="link-underline link-underline-opacity-0 text-body" style="font-size: large;font-weight: bold" :to="{ name:'AboutView' }">{{ $t('About') }}</router-link>
        <div class="mt-2">
            <router-link class="link-underline link-underline-opacity-0 text-body " style="font-size: large;font-weight: bold" :to="{ name:'Products' }">{{ $t('Footer.Products') }}</router-link>
        </div>
        
        <div class="mt-2">
            <router-link class="link-underline link-underline-opacity-0 text-body" style="font-size: large;font-weight: bold" :to="{ name:'AboutView' }">{{ $t('Footer.ContactForm') }}</router-link>
        </div>

        <div class="mt-2">
            <router-link class="link-underline link-underline-opacity-0 text-body" style="font-size: large;font-weight: bold" :to="{ name:'AboutView' }">{{ $t('Footer.Blog') }}</router-link>
        </div>
        </div>

      </div>
   
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0 ">
        <h5 class="text-uppercase text-white">{{ $t('Footer.StoreLocation')}}</h5>

        <div class="google-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3335.575549921669!2d10.0655278!3d53.6009477!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1896683c75229%3A0x55e49b9822359f92!2sBramfelder%20Ch%2097C%2C%2022177%20Hamburg!5e1!3m2!1sen!2sde!4v1725477498367!5m2!1sen!2sde" width="600" height="450" style="border:0;" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: white;">
    © 2020 Copyright:
    <a class="text-body" href="https://github.com/Hina-samreen">Hina Samreen</a>
  </div>
  <!-- Copyright -->
</footer>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "NavBar",
  props: { allCategories: [] },
  components: { Header }

}
</script>

<style scoped>
.contact {

    text-align: justify; 
    font-size: medium;
    font-weight: bolder;

}

.google-map {
     padding-bottom: 50%;
     position: relative;
}

.google-map iframe {
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     position: absolute;
}

.socialMedia {
  position: relative;
  margin-bottom:50px;
  margin-left: 10px;
  width:100%;
}

</style>