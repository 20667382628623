<template>
      
    <div class="container-sm">
      
        <div class="social-menu">
            <ul>
                <li>
                    <a href="https://www.tiktok.com/@rizq.markt" target="blank">
                        <i class="fab fa-brands fa-tiktok"></i>
                    </a>
                </li>

                <li>
                    <a href="https://www.facebook.com/profile.php?id=61560149094776" target="blank">
                        <i class="fab fa-brands fa-facebook"></i>
                    </a>
                </li>

                <li>
                    <a href="https://codepen.io/yourprofile" target="blank">
                        <i class="fab fa-brands fa-whatsapp"></i>
                    </a>
                </li>


                <li>
                    <a href="https://www.instagram.com/yourprofile/"  id="phone" v-on:mouseover="addClassHover" v-on:mouseleave="removeClassHover" target="blank">
                        <i class="fab1 fa-solid fa-phone" :class="{ 'fa-shake': addHover }" id="shake"></i>
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/yourprofile/" id="mail" v-on:mouseover="addClassHover" v-on:mouseleave="removeClassHover" target="blank">
                        <i class="fab1 fa-regular fa-envelope" :class="{ 'fa-beat': addBeat }" id="mail"></i>
                    </a>
                </li>

            </ul>
        </div> 
    </div>   

</template>

<script>
export default {
    name: 'Header',
    data (){
        return {
            addHover: false,
            addBeat: false
        }
    },
    methods: {
        addClassHover(e) {
            if(e.target.id == "shake"){
                this.addHover=true
            }
            else if(e.target.id == "mail") {
                this.addBeat = true
            }
        },
        removeClassHover() {
            
            this.addHover = false
            this.addBeat = false

        }
    }
}


</script>
<style scoped>
.social-menu ul{
        position: absolute;
        top: 40%;
        right:0%;
        left: 70%;
        padding: 0;
        margin: 0;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: end;
    }
    
    .social-menu ul li{
        list-style: none;
        margin: 0 10px;
    }
    
    .social-menu ul li .fab{
        font-size: 15px;
        line-height: 30px;
        transition: .3s;
        color: #000;
    }
    .social-menu ul li .fab1{
        font-size: 15px;
        line-height: 30px;
        transition: .3s;
        color: #000;
        
    }
  

    .social-menu ul li .fab:hover{
        color: #fff;
    }
    
    .social-menu ul li a{
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        text-align: center;
        transition: .6s;
        box-shadow: 0 5px 4px rgba(62, 209, 57, 0.5);
    }
    @media screen and (min-width:1800px ) {
        .social-menu ul{
        position: absolute;
        top: 40%;
        left: 40%;
        padding: 0;
        margin: 0;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: end;
    }        
    }

    @media screen and (max-width: 1500px) {
        .social-menu ul{
        position: absolute;
        top: 40%;
        right:0%;
        left: 90%;
        padding: 0;
        margin: 0;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: end;
    }
    

    }

    @media screen and (min-width: 576px) {

        .social-menu ul li a:hover{
            transform: translate(0, -10%);
            box-shadow: 0 5px 4px rgba(147, 155, 147, 0.5);
        }
        
        .social-menu ul li:nth-child(1) a:hover{
            background-color: rgba(0, 0, 0, 0.829);
        }
        .social-menu ul li:nth-child(2) a:hover{
            background-color: #0077b5;
        }
        .social-menu ul li:nth-child(3) a:hover{
            background-color: #1dd353;  
        }
        .social-menu ul li:nth-child(4) a:hover{
            background-color: #b375e5;
        }
        .social-menu ul li:nth-child(5) a:hover{
            background-color: #ca4926;
            
        }
    }

  

    @media screen and (max-width: 576px) {
    
        .social-menu ul li{
            list-style: none;
            margin: 0 15px;
        }
        
        .social-menu ul li .fab{
            font-size: 20px;
            line-height: 35px;
            transition: .3s;
            color: #000;
        }
        .social-menu ul li .fab1{
            font-size: 20px;
            line-height: 35px;
            transition: .3s;
            color: #000;
            
        }
        .social-menu ul{
            position: absolute;
            bottom: 55%;
            right:0%;
            left: 90%;
            transform: translate(-50%, -50%);
        
        }
        .social-menu ul li a{
            position: relative;
            display: block;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            transition: .6s;
            box-shadow: 0 5px 4px rgba(210, 157, 22, 0.859);
        }
        .social-menu ul li .fab:hover{
            color: #302c2c;
        }
        
    }
    @media screen and (width:540px) {

         .social-menu ul{
            position: absolute;
            bottom: 0%;
            top: 45%;
            right:0%;
            left: 100%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: end;
        }
    }
    
</style>