<template>
  <div class="page-checkout  ">
  <section class="pt-md-0 pt-5" id="checkout">
    
    <div class="container  pt-5 pt-lg-0 ">
      <div class="row pt-5 p-md-5 gx-0 ">

        <div class="col-12 d-block d-lg-none">
          <div class="accordion mb-4 w-100" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed fs-4 fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummaryCollapse" aria-expanded="false" aria-controls="orderSummaryCollapse">
                Order Summary
              </button>
             </h2>
             <div id="orderSummaryCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div v-for="item in cart.items" :key="item.product.id" class="card card-small mx-auto  p-md-3 ms-lg-5 mb-3" >
                  <div class="card-body">
                    <div class="d-flex ">
                      
                        <div>
                          <img
                            :src="item.product.get_thumbnail"
                            class="img-fluid rounded-3 ms-auto" style="width: 65px;">
                        </div>
                       
                      <div class="d-block align-items-center">
                        <div class="text-start ms-2">
                          <p class="text-capitalize"  style="font-size: medium;">{{ item.product.name }}</p>
                          <p class="text-lowercase"  style="font-size: medium;">{{ item.product.brand }}</p>
                          <p class="" style="font-size: medium;font-weight: lighter;">Qty:{{ item.quantity }}</p>
                        </div>
                      </div>
                       
                        <div v-if="item.product.offer" class="ms-5" >
                          <h5 class="mb-0 mt-2 text-danger " style="font-size: medium;">€{{ getItemTotalOffer(item) }}</h5>
                          <p class="card-text fs-6 fw-bolder text-decoration-line-through" >€{{ getItemTotal(item) }}</p> 
                        </div>
                        <div v-else>
                          <h5 class="mb-0 mt-2 ms-5" style="font-size: medium;">€{{ getItemTotal(item) }}</h5>
                        </div>
                    
                    </div>
                  </div>
                </div>
                        </div>
                      </div>
                    </div>
          </div>
        </div>
        <div class="col-12 col-lg-7 border rounded text-white fw-bolder" style="background-color:#77b477;">
          <div class="p-3 w-100" style="width:300px;">
          <h2 class="pb-3">Shipping Details</h2>

          <div class="row g-3">
            <div class="col-md-6">
              <label class="form-label checkout-labels">First name*</label>
              <input
                type="text"
                class="form-control"
                v-model="first_name"
                required
              />
            </div>
            <div class="col-md-6">
              <label class="form-label checkout-labels">Last name*</label>
              <input type="text" class="form-control" required v-model="last_name" />
            </div>
            <div class="col-md-6">
              <label class="form-label checkout-labels"
                >Email</label
              >
              <input type="email" class="form-control" v-model="email" required />
            </div>

            <div class="col-md-6">
              <label class="form-label checkout-labels">Phone*</label>
              <input type="text" class="form-control" v-model="phone" required />
            </div>

            <div class="col-12">
              <label class="form-label checkout-labels"
                >Address*</label
              >
              <input
                type="text"
                class="form-control"
                v-model="address"
                placeholder="1234 Main St"
                required
              />
            </div>
            <div class="col-12">
              <label class="form-label checkout-labels"
                >Address 2</label
              >
              <input
                type="text"
                class="form-control"
                v-model="address2"
                placeholder="Apartment, studio, or floor"
              />
            </div>
            <div class="col-md-6">
              <label  class="form-label checkout-labels"
                >City*</label
              >
              <input type="text" class="form-control" required v-model="city" />
            </div>
            <div class="col-md-4">
              <label class="form-label checkout-labels"
                >Country</label
              >
              <select id="inputState" class="form-select" v-model="country" required> 
                <option selected>Choose...</option>
                <option>Germany</option>
              </select>
            </div>
            <div class="col-md-2">
              <label class="form-label checkout-labels"
                >Zip</label
              >
              <input type="text" class="form-control" required v-model="zipcode" />
            </div>
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" />
                <label
                  class="form-check-label checkout-labels"
                  for="gridCheck"
                >
                  Save the details for later use.
                </label>
              </div>
            </div>

            <div class="col-12 alert alert-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>

            
            <div class="border border-success border-5 rounded mx-auto ms-0  payment-small mt-3 " id="payment">
                <h2 class="m-4">Payment Methods</h2>
              
                <div class="form-check d-flex flex-row justify-content-start m-3">
                  <input class="form-check-input me-3 fs-5" type="radio"  name="paymentMethods" id="cod" value="COD" checked  v-model="paymentMethod" @click="checkedRadioCard= false;checkedRadioCOD=true;">
                  <label class="form-check-label fs-5" for="cod">Cash on Delivery</label>
                
                </div>

                <div class="m-3" id="CashOnDelivery" v-if="checkedRadioCOD">
                      <hr class="mt-4">

                      <div class="d-flex justify-content-between fs-5 fw-bolder">
                        <p class="mb-2 ">Subtotal</p>
                        <p class="mb-2">€{{ cartSubTotalPrice }}</p>
                      </div>

                      <div class="d-flex justify-content-between fs-5 fw-bolder">
                        <p class="mb-2">Shipping</p>
                        <p class="mb-2">{{ shippingFeeString }}</p>
                      </div>

                      <div class="d-flex justify-content-between mb-4 fs-4 fw-bolder">
                        <p class="mb-2">Total(Incl. taxes)</p>
                        <p class="mb-2">€{{ cartTotalPrice }}</p>
                      </div>

                      <button  type="button" class="btn btn-success button-small m-3 p-2 mx-auto" @click.prevent="submitCodForm" >
                        <div class="d-flex justify-content-between fw-bolder">
                          <span class="pe-3 mx-auto fs-5">Checkout</span>
                        </div>
                      </button>
                  </div>

                <div class="form-check d-flex flex-row justify-content-start m-3" >
                  <input class="form-check-input me-3 fs-5" type="radio" name="paymentMethods" id="card" value="card" v-model="paymentMethod" @click="checkedRadioCOD= false;checkedRadioCard=true;cardDetails()" >
                  <label class="form-check-label fs-5" for="card">Pay with Stripe</label>

                </div>

              <div class="mb-5 w-100" v-show="checkedRadioCard">

                <div id="card-element" class="mt-5 bg-light p-3 text-white" style="inline-size: 100% ;" ></div>
                <button class="btn btn-success button-small m-3 mt-5 p-2 mx-auto fs-5 fw-bold" @click="submitForm">Pay with Stripe</button>
              </div>
                
              
              </div>
            
          </div>
         
          </div>
         
        </div>
        <div class="col-12 col-lg-5 ">
          <div class="d-none d-lg-block">
          <h5 class="mb-3 mx-auto"><a href="#!" class="link-dark link-underline link-underline-opacity-0  fs-5 ps-lg-5">Order Summary</a></h5>
                <hr class="hr-small ms-lg-5 mx-auto">

            <div class="mb-4 d-flex ">
                  <div class="mx-auto">
                    <p class="mb-0">You have {{ cartTotalLength }} items in your cart</p>
                  </div>
                  <!--<div>
                    <p class="text-end"><span class="text-muted">Sort by:</span> <a href="#!"
                        class="text-body text-end">price <i class="fas fa-angle-down mt-1"></i></a></p>
                  </div>-->
                </div>

          <div v-for="item in cart.items" :key="item.product.id" class="card card-small mx-auto p-3 ms-lg-5 mb-3" >
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      
                        <div>
                          <img
                            :src="item.product.get_thumbnail"
                            class="img-fluid rounded-3" style="width: 65px;">
                        </div>
                       
                      <div class="d-block align-items-center mt-3 text-capitalize">
                        <div class="ms-3 ">
                          <h6 class="fd-7">{{ item.product.name }}</h6>
                          <p class="fs-6 mb-0">{{ item.product.brand }}</p>
                        </div>
                      </div>
                        <div >
                          <h6 class="fw-light fs-6 mt-3 ms-2">Qty:{{ item.quantity }}</h6>
                        </div>
                        
                        <div v-if="item.product.offer" class="ms-5" >
                          <h5 class="mb-0 mt-2 text-danger " style="font-size: medium;">€{{  getItemTotalOffer(item) }}</h5>
                          <p class="card-text fs-6 fw-bolder text-decoration-line-through" >€{{  getItemTotal(item) }}</p> 
                        </div>
                        <div v-else>
                          <h5 class="mb-0 mt-4 ms-5" style="font-size: medium;">€{{ getItemTotal(item) }}</h5>
                        </div>
                    
                    </div>
                  </div>
                </div>
              </div>
                
              
            
        </div>
       

        
      </div>
    </div>
  </section>

</div>
</template>

<script>
import axios from "axios";
export default {
name: "CheckOut",
data() {
  return {
    cart: {
      items: [],
    },
    paymentMethod: "COD",
    checkedRadioCOD: true,
    checkedRadioCard:false,
    stripe: {},
    card: {},
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    address2:"",
    country:"",
    zipcode: "",
    city: "",
    errors: [],
    shippingFeeString:'',
    shippingFee:0
  }
},
mounted() {
  document.title = "Checkout | Rizq";
 

  this.cart = this.$store.state.cart

  
 if(this.checkedRadio == true) {
    if(this.cartTotalLength > 0){
   
    this.stripe = Stripe('pk_test_51PuHF3P5NzAuHjvXg4sSRiC9IUeUMZ0x38nut2HNqk9HMsBe3SH6n8lclrocj18NfFVORpDihmgf31sbTGmd8oWC0060NMXy5J')
    var elements = this.stripe.elements();
    this.card = elements.create('card', { hidePostalCode: true })

    this.card.mount('#card-element')
    }
  }
  
  /*if(this.cartTotalLength > 0){
    this.stripe = Stripe('pk_test_51PuHF3P5NzAuHjvXg4sSRiC9IUeUMZ0x38nut2HNqk9HMsBe3SH6n8lclrocj18NfFVORpDihmgf31sbTGmd8oWC0060NMXy5J')
    var elements = this.stripe.elements();
    this.card = elements.create('card', { hidePostalCode: true })

    this.card.mount('#card-element')
  }*/
},
methods: {
        getItemTotalOffer(item) {
            if(item.product.offer) {
              const price = item.quantity * item.product.offerPrice
              return parseFloat(price.toFixed(2))
            }
            },
        getItemTotal(item){
          const price = item.quantity * item.product.price
            return parseFloat(price.toFixed(2))
            
        },
        cartSubTotalOfferPrice(){
      
      var total = 0
      this.cart.items.forEach((item) => {
  
        if(item.product.offer){
          const total_price = item.quantity * item.product.offerPrice 
          total += total_price 
        }
        else {
          const total_price = item.quantity * item.product.price
          total += total_price 
        }
      })  
      return parseFloat(total.toFixed(2))
    },

    CartTotalOfferPrice(){
      var total = 0
      this.cart.items.forEach((item) => {
        if(item.product.offer){
          const total_price = item.quantity * item.product.offerPrice 
          total += total_price 
        }
      })  
     if(parseFloat(total.toFixed(2)) < 39) {
        total += 3.99
        this.shippingFeeString = '€3.99'
        this.shippingFee = 3.99
     }
     else {
      this.shippingFeeString ='Free'
      this.shippingFee = 0
     }
     return parseFloat(total.toFixed(2))
    },
       

  cardDetails(){

    if(this.checkedRadioCard == true) {
  
    if(this.cartTotalLength > 0){
    
    this.stripe = Stripe('pk_test_51PuHF3P5NzAuHjvXg4sSRiC9IUeUMZ0x38nut2HNqk9HMsBe3SH6n8lclrocj18NfFVORpDihmgf31sbTGmd8oWC0060NMXy5J')
    var elements = this.stripe.elements();
    this.card = elements.create('card', { hidePostalCode: true })

    this.card.mount('#card-element')
    }
  }
  },
  async submitCodForm() {


    this.errors = []

    if (this.first_name === '') {
                this.errors.push('The first name field is missing!')
            }

            if (this.last_name === '') {
                this.errors.push('The last name field is missing!')
            }

            if (this.email === '') {
                this.errors.push('The email field is missing!')
            }

            if (this.phone === '') {
                this.errors.push('The phone field is missing!')
            }

            if (this.address === '') {
                this.errors.push('The address field is missing!')
            }

            if (this.zipcode === '') {
                this.errors.push('The zip code field is missing!')
            }

            if (this.city === '') {
                this.errors.push('The city field is missing!')
            }
            if (this.country === '') {
                this.errors.push('The city field is missing!')
            }
            


if(!this.errors.length) {
  this.$store.commit('setIsLoading', true)
  const items= []

  for(let i =0; i< this.cart.items.length; i++) {
    const item = this.cart.items[i]

    const obj = {
      product: item.product.id,
      quantity: item.quantity,
      price: (item.product.offer) ? parseFloat(item.product.offerPrice * item.quantity).toFixed(2) : parseFloat(item.product.price * item.quantity).toFixed(2)
    }

    items.push(obj)
  }

  const data = {
    'first_name': this.first_name,
    'last_name':this.last_name,
    'email': this.email,
    'address': this.address,
    'address2': this.address2,
    'zipcode':this.zipcode,
    'city': this.city,
    'phone': this.phone,
    'country':this.country,
    'items':items,
    'stripe_token':'paid via COD',
    'payment_method':this.paymentMethod,
    'shipping_fee':this.shippingFee
  }

  await axios
    .post('/api/v1/checkout/', data)
    .then(response => {
      this.$store.commit('clearCart')
      this.$router.push('/cart/success')
    })
    .catch(error => { 
      this.errors.push('Seomthing went wrong. Please try again')

      console.log(error.response.data)
    })

    this.$store.commit('setIsLoading', false)
 
}

},

  submitForm() {

  this.errors = []

  if (this.first_name === '') {
                this.errors.push('The first name field is missing!')
            }

            if (this.last_name === '') {
                this.errors.push('The last name field is missing!')
            }

            if (this.email === '') {
                this.errors.push('The email field is missing!')
            }

            if (this.phone === '') {
                this.errors.push('The phone field is missing!')
            }

            if (this.address === '') {
                this.errors.push('The address field is missing!')
            }

            if (this.zipcode === '') {
                this.errors.push('The zip code field is missing!')
            }

            if (this.city === '') {
                this.errors.push('The city field is missing!')
            }
            if (this.country === '') {
                this.errors.push('The city field is missing!')
            }
            


  if(!this.errors.length) {
    this.$store.commit('setIsLoading', true)

    this.stripe.createToken(this.card).then(result => {                    
                    if (result.error) {
                        this.$store.commit('setIsLoading', false)

                        this.errors.push(result.error.message)

                        console.log(result.error.message)
                    } else {
                        this.stripeTokenHandler(result.token)
                    }
                })
  }
},

async stripeTokenHandler(token) {
  const items= []

  for(let i =0; i< this.cart.items.length; i++) {
    const item = this.cart.items[i]
    const obj = {
      product: item.product.id,
      quantity: item.quantity,
      price: parseFloat(item.product.price * item.quantity).toFixed(2)
    }

    items.push(obj)
  }

  const data = {
    'first_name': this.first_name,
    'last_name':this.last_name,
    'email': this.email,
    'address': this.address,
    'address2': this.address2,
    'zipcode':this.zipcode,
    'city': this.city,
    'phone': this.phone,
    'country':this.country,
    'items':items,
    'stripe_token':token.id,
    'payment_method':this.paymentMethod,
    'shipping_fee':this.shippingFee
  }

  await axios
    .post('/api/v1/checkout/', data)
    .then(response => {
      this.$store.commit('clearCart')
      this.$router.push('/cart/success')
    })
    .catch(error => { 
      this.errors.push(error.response.data)

      console.log(error.response.data)
    })

    this.$store.commit('setIsLoading', false)
 
}


},
computed: {
cartSubTotalPrice() {
return this.cart.items.reduce((acc, curVal) => {

  if(curVal.product.offer) {
    acc += curVal.product.offerPrice * curVal.quantity
  }
  else {
    acc += curVal.product.price * curVal.quantity
  }
  return acc 
}, 0)
},
cartTotalPrice() {
  var total = this.cartSubTotalPrice
  if(total < 39) {
    total += 3.99
    this.shippingFee = 3.99
    this.shippingFeeString = '€3.99'
  }
  else{
    total = total
    this.shippingFee = 0
    this.shippingFeeString = 'Free'
  }
  return total
  
 
},
cartTotalLength() {
return this.cart.items.reduce((acc, curVal) => {
  return acc += curVal.quantity;
},0)
}
}
};
</script>

<style scoped>

.accordion {
  --bs-accordion-active-bg: #77b477 ;
 
}

.stripe-card-group, div#stripe-cvc-element, div#stripe-exp-element {
        width: 100% !important;
    }

.card-small {
  width:100%;
}

.hr-small {
  width:100%;
}

.button-small {
  width:70%;
}

.payment-small{
  width: 100%;
}

.checkout-labels {
display: flex;
justify-content: flex-start;
font-size: large;
}

@media (min-width: 1025px) {
.h-custom {
height: 100vh !important;
}
}

@media screen and (max-width: 576px) {
  .card-small {
    width:100%;
  }
  .hr-small {
  width:350px;
}
.button-small {
  width:100%;
  font-weight: bolder;
}

}

</style>
