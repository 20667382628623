<template>
    <section id="success" class="pt-5 my-5">
        <div class="container-fluid text-center border border-success pt-5 my-5">
            <h1>Thank you!</h1>
            <p class="mb-5"> Your Order will be processed as soon as possible!</p>
            <p>Please check your mail for the invoice!</p>

        </div>
    </section>
</template>

<script>

export default {
    name:'SuccessView',
    mounted() {
        document.title ='Success | Rizq'
    }
}
</script>
